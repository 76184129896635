import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PricingProfile, PricingProfileApi } from 'types/pricingProfile';
import { ApiGenericCallbackPayload, AsyncThunkConfig, ReduxStatusEnum } from 'types';
import { axiosRequest } from 'api/axiosRequest';
import { hideWindowLoader, showWindowLoader } from '../ui/windowLoaderSlice';
import { getPricingProfileInitialItemsState } from '../../../utils/models/pricingProfile';

interface IInitialState {
  list: PricingProfile[];
  item: PricingProfile;
  itemApi: PricingProfileApi;
  status: ReduxStatusEnum;
  error: unknown | string | null;
}

export const getAllPricingProfiles = createAsyncThunk<PricingProfile[], AsyncThunkConfig>(
  'pricingProfiles/getAll',
  async ({}, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/pricing-profiles`);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getPricingProfile = createAsyncThunk<PricingProfileApi, { id: string }, AsyncThunkConfig>(
  'pricingProfiles/getItem',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get(`/pricing-profiles/${id}`);
      return { id, ...response.data };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createPricingProfile = createAsyncThunk<
  PricingProfileApi,
  ApiGenericCallbackPayload<PricingProfileApi>,
  AsyncThunkConfig
>('pricingProfiles/create', async ({ body, onSuccessCallback, onErrorCallback }, { dispatch, rejectWithValue }) => {
  try {
    dispatch(showWindowLoader({}));
    await axiosRequest.post(`/pricing-profiles`, body);
    onSuccessCallback && onSuccessCallback();
    dispatch(hideWindowLoader({}));

    return body as PricingProfileApi;
  } catch (e) {
    dispatch(hideWindowLoader({}));
    return rejectWithValue(e);
  }
});

export const updatePricingProfile = createAsyncThunk<
  PricingProfileApi,
  ApiGenericCallbackPayload<PricingProfileApi> & { id: string },
  AsyncThunkConfig
>('pricingProfiles/update', async ({ id, body, onSuccessCallback, onErrorCallback }, { dispatch, rejectWithValue }) => {
  try {
    dispatch(showWindowLoader({}));
    await axiosRequest.put(`/pricing-profiles/${id}`, body);
    onSuccessCallback && onSuccessCallback();
    dispatch(hideWindowLoader({}));

    return { ...body, id } as PricingProfileApi;
  } catch (e) {
    dispatch(hideWindowLoader({}));
    return rejectWithValue(e);
  }
});

const pricingProfilesSlice = createSlice({
  name: 'pricingProfiles',
  initialState: {
    list: [] as PricingProfile[],
    item: {
      name: '',
      items: getPricingProfileInitialItemsState(),
    } as PricingProfile,
    itemApi: {} as PricingProfileApi,
    status: ReduxStatusEnum.IDLE,
    error: null,
  } as IInitialState,
  reducers: {
    resetPricingProfileApi: (state) => {
      state.itemApi = {} as PricingProfileApi;
    },
  },
  extraReducers: (builder) => {
    builder
      // get list
      .addCase(getAllPricingProfiles.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(getAllPricingProfiles.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.list = action.payload;
      })
      .addCase(getAllPricingProfiles.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // get item
      .addCase(getPricingProfile.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(getPricingProfile.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.itemApi = action.payload;
      })
      .addCase(getPricingProfile.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // create
      .addCase(createPricingProfile.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(createPricingProfile.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
      })
      .addCase(createPricingProfile.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // update
      .addCase(updatePricingProfile.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(updatePricingProfile.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.itemApi = { ...state.itemApi, ...action.payload };
      })
      .addCase(updatePricingProfile.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetPricingProfileApi } = pricingProfilesSlice.actions;

export default pricingProfilesSlice.reducer;
