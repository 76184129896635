export const COLORS = {
  ICON: '#737373',
  PRIMARY_MAIN: '#2a76f4',
  GREEN: '#66bb6a',
  BLUE: '#29b6f6',
  YELLOW: '#ffc400',
  RED: '#f44336',
  GREY: '#9e9e9e',
  TEXT: '#03060B',
};
