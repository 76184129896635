import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@mui/material';

import { useSelector } from 'react-redux';
import { theme } from 'themes/defaultTheme';
import { breadcrumbsDictionary } from 'utils';

function LinkRouter(props) {
  return <Link style={{ color: theme.palette.primary.main, fontSize: '14px' }} {...props} component={RouterLink} />;
}

let currentIndex = 0;

export function BreadcrumbsComponent() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const { id } = useParams();
  const pageTitle = useSelector((state) => state.pageTitle);

  useEffect(() => {
    currentIndex = 0;
  }, [pathnames]);

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={
        <Typography color={theme.palette.grey[500]} fontSize="11px" pt={0.4}>
          /
        </Typography>
      }
    >
      <LinkRouter underline="hover" color="inherit" to="/">
        Home
      </LinkRouter>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = decodeURI(`/${pathnames.slice(0, index + 1).join('/')}`);

        let breadcrumbsItem = breadcrumbsDictionary(id)[to];
        if (!breadcrumbsItem) {
          breadcrumbsItem = pageTitle.breadcrumbParams?.length
            ? pageTitle.breadcrumbParams[currentIndex]
            : pageTitle.title;
          if (pageTitle.breadcrumbParams?.length > 1) {
            currentIndex++;
          }
        }

        return last ? (
          <Typography fontSize="14px" color="text.primary" key={to}>
            {breadcrumbsItem}
          </Typography>
        ) : (
          <LinkRouter underline="hover" color="inherit" to={to} key={to}>
            {breadcrumbsItem}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
}
