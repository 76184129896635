export const CLIENT_INDIVIDUAL = 'individual';
export const CLIENT_COMPANY = 'company';
export const CLIENT_ID_TENANT = 'tenant';

export const getClientSelectDataFromObject = (object) => {
  if (object.clientId) {
    return {
      id: object.clientId,
      label: object.clientName,
    };
  }
  return {};
};
