import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { hideWindowLoader, showWindowLoader } from 'redux/slices/ui/windowLoaderSlice';
import { locationInitialState } from 'utils/models/location';
import { axiosRequest } from 'api/axiosRequest';
import { getStationById } from 'api/requests/stationRequest';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { createLocationRequest } from 'api/requests/locationRequest';
import { showSuccessToast } from '../ui/toastAlertSlice';

export const fetchStation = createAsyncThunk('station/fetchStation', async (id, thunkApi) => {
  try {
    return getStationById(id);
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const registerSingleStation = createAsyncThunk(
  'station/createStation',
  async ({ stationId, station, location, onSuccessCallback }, { rejectWithValue }) => {
    try {
      let locationId = location.id;
      if (!locationId) {
        const responseLocation = await createLocationRequest(location);
        locationId = responseLocation.locationId;
      }

      const response = await axiosRequest.post(`/register/station/${stationId}`, { ...station, locationId });
      onSuccessCallback && onSuccessCallback();
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const importSingleStation = createAsyncThunk(
  'station/importStation',
  async ({ id, details, onSuccessCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.post(`/import/station/${id}`, {
        details: {
          ...details,
        },
      });
      onSuccessCallback && onSuccessCallback();
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateStationName = createAsyncThunk(
  'station/updateStationName',
  async ({ stationId, name, onSuccessCallback }, { rejectWithValue }) => {
    try {
      await axiosRequest.put(`/stations/${stationId}/name`, {
        name,
      });
      onSuccessCallback && onSuccessCallback();
      return { name };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateStationFirmwareVersion = createAsyncThunk(
  'station/updateStationFirmwareVersion',
  async ({ stationId, firmwareVersion }) => {
    // const response = await axiosRequest.put(
    // 	`/stations/${stationId}/firmwareVersion`, {firmwareVersion}
    // )
    // return {...response.data, name: name}
    return {};
  }
);

export const updateStationAmperage = createAsyncThunk(
  'station/updateStationAmperage',
  async ({ stationId, amperage, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      await axiosRequest.put(`/stations/${stationId}/amperage`, { amperage });
      onSuccessCallback && onSuccessCallback();
      return { amperage };
    } catch (e) {
      onErrorCallback && onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

// TODO API
export const updateStationAutoStartMode = createAsyncThunk(
  'station/updateStationAutoStartMode',
  async ({ stationId, autoStart, rfId, onSuccessCallback }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(showWindowLoader());

      await axiosRequest.put(`/stations/${stationId}/autostart`, { autoStart, rfId });
      onSuccessCallback && onSuccessCallback();
      dispatch(hideWindowLoader());

      return { autoStart: autoStart ? rfId : false };
    } catch (e) {
      dispatch(hideWindowLoader());

      return rejectWithValue(e);
    }
  }
);

export const updateStationSupportedCard = createAsyncThunk(
  'station/updateStationSupportedCard',
  async ({ stationId, body, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      await axiosRequest.put(`/stations/${stationId}/accepted-cards`, [...body]);
      onSuccessCallback && onSuccessCallback();
      return { acceptedCards: body };
    } catch (e) {
      onErrorCallback && onErrorCallback();

      return rejectWithValue(e);
    }
  }
);

export const updateStationChargingType = createAsyncThunk(
  'station/updateStationChargingType',
  async ({ stationId, chargingType, onSuccessCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.put(`/stations/${stationId}/chargingtype`, { chargingType });
      onSuccessCallback && onSuccessCallback();
      return { chargingType };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateStationPrices = createAsyncThunk(
  'station/updateStationPrices',
  async ({ stationId, chargingType, prices, connectors, onSuccessCallback }, { rejectWithValue }) => {
    try {
      await axiosRequest.put(`/stations/${stationId}/chargingtype`, { chargingType });

      const newConnectors = [];

      const promises = connectors.map(async (connector, i) => {
        await axiosRequest.put(`/stations/${stationId}/price/${connectors[i].index}`, {
          price: prices[i].price,
          currency: prices[i].currency,
          startPrice: prices[i].startPrice,
        });

        newConnectors.push({
          ...connector,
          price: prices[i].price,
          currency: prices[i].currency,
          startPrice: prices[i].startPrice,
        });
      });
      await Promise.all(promises);

      onSuccessCallback && onSuccessCallback();
      return { chargingType, connectors: newConnectors };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateStationSettings = createAsyncThunk(
  'station/updateStationSettings',
  async ({ stationId, chargingtype, autostart, amperage }, thunkApi) => {
    try {
      const response = await axiosRequest.put(`/stations/${stationId}/settings`, { chargingtype, autostart, amperage });
      thunkApi.dispatch(showSuccessToast({ message: 'Settings updated' }));
      return { chargingtype, autostart, amperage };
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const updateStationLocation = createAsyncThunk(
  'station/updateStationLocation',
  async ({ stationId, locationId, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      await axiosRequest.put(`/stations/${stationId}/location/${locationId}`, {});
      onSuccessCallback && onSuccessCallback();
      return { locationId };
    } catch (e) {
      onErrorCallback && onErrorCallback();
      return rejectWithValue(e);
    }
  }
);

export const removeStation = createAsyncThunk(
  'station/removeStation',
  async ({ stationId, onSuccessCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.delete(`/stations/${stationId}`);
      onSuccessCallback && onSuccessCallback();
      return { stationId };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const initialState = {
  station: {},
  status: STATUS_IDLE,
  error: null,
};

export const stationSlice = createSlice({
  name: 'station',
  initialState,
  reducers: {
    resetStation: {
      reducer: (state) => {
        state.station = initialState.station;
        state.status = initialState.status;
        state.error = initialState.error;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStation.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchStation.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        if (!action.payload.location) {
          state.station = { ...action.payload, location: locationInitialState };
        } else {
          state.station = action.payload;
        }
      })
      .addCase(fetchStation.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // register station
      .addCase(registerSingleStation.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(registerSingleStation.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.station = action.payload.response;
      })
      .addCase(registerSingleStation.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // import station
      .addCase(importSingleStation.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(importSingleStation.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.station = action.payload.response;
      })
      .addCase(importSingleStation.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // update station name
      .addCase(updateStationName.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateStationName.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.station.details.name = action.payload.name;
      })
      .addCase(updateStationName.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // update station firmware version
      .addCase(updateStationFirmwareVersion.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateStationFirmwareVersion.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(updateStationFirmwareVersion.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // update station amperage
      .addCase(updateStationAmperage.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateStationAmperage.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.station.details.amperage = action.payload.amperage;
      })
      .addCase(updateStationAmperage.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // update station autostart mode
      .addCase(updateStationAutoStartMode.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateStationAutoStartMode.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.station.details.autoStart = action.payload.autoStart;
      })
      .addCase(updateStationAutoStartMode.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // update station charging type
      .addCase(updateStationChargingType.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateStationChargingType.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.station.details.chargingType = action.payload.chargingType;
      })
      .addCase(updateStationChargingType.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // update station settings
      .addCase(updateStationSettings.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateStationSettings.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.station.details = { ...state.station.details, ...action.payload };
      })
      .addCase(updateStationSettings.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // update station location
      .addCase(updateStationLocation.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateStationLocation.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.station.locationId = action.payload.locationId;
      })
      .addCase(updateStationLocation.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // remove station
      .addCase(removeStation.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(removeStation.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.station = {};
      })
      .addCase(removeStation.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // update station prices
      .addCase(updateStationPrices.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateStationPrices.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.station.details.chargingType = action.payload.chargingType;
        state.station.connectors = action.payload.connectors;
      })
      .addCase(updateStationPrices.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // update station supported cards
      .addCase(updateStationSupportedCard.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateStationSupportedCard.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.station.acceptedCards = action.payload.acceptedCards;
      })
      .addCase(updateStationSupportedCard.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetStation } = stationSlice.actions;

export default stationSlice.reducer;
