import { Billing, BillingPlan } from './billing';

export interface Client {
  id: string;
  user: UserClient;
  details: ClientDetails;
  billing: Billing;
  billing_plan?: BillingPlan;
  confirmed?: boolean;
  created_at: string;
  updated_at: string;
  totalConnectors?: number;
}

export enum ClientType {
  company = 'company',
  individual = 'individual',
}

export interface ClientDetails {
  type: ClientType;
  personalAddress: string;
  companyName?: string;
  companyId?: string;
  companyAddress?: string;
}

export interface UserClient {
  email: string;
  firstName: string;
  lastName: string;
  details: UserDetails;
  role?: string;
}

export interface UserDetails {
  phone: string;
}
