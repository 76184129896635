import { createTheme } from '@mui/material/styles';

import componentsOverride from './overrides';
import { customPalette } from './customPalette';
import { customShadows } from './customShadows';
import { customSpacing } from './customSpacing';
import { customTypography } from './customTypography';
import { CustomTheme } from '../types';

const DEFAULT_BORDER_RADIUS = 6;

export const modalBoxShadowStyle = {
  border: '1px solid rgb(224, 227, 231)',
  boxShadow: 'rgb(170 180 190 / 30%) 0px 4px 20px',
  borderRadius: `${DEFAULT_BORDER_RADIUS}px`,
  backgroundColor: 'white',
};

export const boxStyle = {
  backgroundColor: '#fafafa',
  borderRadius: `${DEFAULT_BORDER_RADIUS}px`,
  padding: 1,
  height: '100%',
  boxShadow: 'rgb(170 180 190 / 30%) 0px 1px 5px',
};

export const colors = {
  backgroundColorDark: '#160833',
};

export const defaultTabsStyle = {
  '& .MuiTab-root': {
    fontSize: 14,
    backgroundColor: 'white',
  },
  '& .MuiTabs-scroller': {
    backgroundColor: 'white',
  },
  '& .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary': {
    color: 'text.primary',
  },
  '& .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected': {
    color: 'primary.main',
  },
  '& .MuiTabs-indicator': {
    height: 2.5,
  },
};

const defaultTheme = createTheme();

const themeObj = {
  ...defaultTheme,
  typography: {
    ...defaultTheme.typography,
    ...customTypography,
  },
  shadows: [...defaultTheme.shadows, ...customShadows],
  palette: {
    ...defaultTheme.palette,
    primary: {
      ...defaultTheme.palette.primary,
      ...customPalette.palette.primary,
    },
    customColors: { ...customPalette.palette.customColors },
  },
  shape: { borderRadius: DEFAULT_BORDER_RADIUS },
} as unknown as CustomTheme;
themeObj.components = componentsOverride(themeObj);
themeObj.customSpacing = customSpacing(themeObj);

export const theme = createTheme(themeObj) as unknown as CustomTheme;
