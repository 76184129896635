import { BillingPlan } from '../../types';

export const BILLING_PLAN_DEFAULT = {
  subscription: {
    currency: 'RON',
    pricePerConnector: undefined,
  },
  l1Support: {
    currency: 'RON',
    pricePerConnector: undefined,
  },
} as BillingPlan;
