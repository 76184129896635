import { theme } from 'themes/defaultTheme';

export const CARD_STATUS_ACTIVE = 'Active';
export const CARD_STATUS_NOT_ACTIVATED = 'Not activated';
export const CARD_STATUS_DEACTIVATED = 'Deactivated';
export const CARD_STATUS_EXPIRED = 'Expired';
export const CARD_NOT_YET_VALID = 'Not yet valid';

export const CARD_TYPES = [
  {
    id: 'card',
    label: 'RFID',
  },
  {
    id: 'token',
    label: 'RFID',
  },
  {
    id: 'APP_USER',
    label: 'Mobile',
  },
  {
    id: 'mobile_public',
    label: 'Mobile public',
  },
  {
    id: 'Mobile',
    label: 'Mobile',
  },
  {
    id: 'RFID',
    label: 'RFID',
  },
];

export const getCardTypeLabel = (type) => {
  return CARD_TYPES.find((cardType) => cardType.id === type)?.label ?? '';
};

export const cardsStatusColor = {
  [CARD_STATUS_ACTIVE]: {
    name: CARD_STATUS_ACTIVE,
    color: theme.palette.customColors.statuses.green,
  },
  [CARD_STATUS_NOT_ACTIVATED]: {
    name: CARD_STATUS_NOT_ACTIVATED,
    color: theme.palette.customColors.statuses.grey,
  },
  [CARD_STATUS_DEACTIVATED]: {
    name: CARD_STATUS_DEACTIVATED,
    color: theme.palette.common.black,
  },
  [CARD_STATUS_EXPIRED]: {
    name: CARD_STATUS_EXPIRED,
    color: theme.palette.customColors.statuses.red,
  },
  [CARD_NOT_YET_VALID]: {
    name: CARD_NOT_YET_VALID,
    color: theme.palette.customColors.statuses.blue,
  },
};

export const getCardStatusColor = (status) => {
  return cardsStatusColor[status]?.color ?? theme.palette.common.black;
};
