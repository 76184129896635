import { createSlice } from '@reduxjs/toolkit';
import { BILLING_PLAN_DEFAULT } from 'utils/models/billingPlan';

const initialState = {
  companyDetails: {
    name: '',
    vatNumber: '',
    address: '',
  },
  personalDetails: {
    lastName: '',
    firstName: '',
    email: '',
    phone: '',
  },
  billingDetails: {
    bank: '',
    holder: '',
    iban: '',
    swift: '',
    country: '',
  },
  billingPlan: BILLING_PLAN_DEFAULT,
  activeStep: 0,
};

const registerCompanyClientStepSlice = createSlice({
  name: 'registerCompanyClientStep',
  initialState: {
    data: initialState,
  },
  reducers: {
    setCompanyPersonalDetails: {
      reducer: (state, action) => {
        state.data.personalDetails = {
          ...state.data.personalDetails,
          ...action.payload,
        };
      },
    },
    setCompanyDetails: {
      reducer: (state, action) => {
        state.data.companyDetails = {
          ...state.data.companyDetails,
          ...action.payload,
        };
      },
    },
    setCompanyBillingDetails: {
      reducer: (state, action) => {
        state.data.billingDetails = {
          ...state.data.billingDetails,
          ...action.payload,
        };
      },
    },
    setCompanyBillingPlan: {
      reducer: (state, action) => {
        state.data.billingPlan = {
          ...state.data.billingPlan,
          ...action.payload,
        };
      },
    },
    resetRegisterCompanyClientStep: {
      reducer: (state) => {
        state.data = initialState;
      },
    },
    increaseRegisterCompanyClientStep: {
      reducer: (state) => {
        const stepSize = 1;

        state.data.activeStep += stepSize;
      },
    },
    decreaseRegisterCompanyClientStep: {
      reducer: (state) => {
        const stepSize = 1;
        state.data.activeStep -= stepSize;
      },
    },
  },
});

export const {
  setCompanyPersonalDetails,
  setCompanyBillingDetails,
  setCompanyDetails,
  setCompanyBillingPlan,
  resetRegisterCompanyClientStep,
  increaseRegisterCompanyClientStep,
  decreaseRegisterCompanyClientStep,
} = registerCompanyClientStepSlice.actions;

export default registerCompanyClientStepSlice.reducer;
