export const notificationTypes = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Unread',
    value: 'unread',
  },
  {
    label: 'Warning',
    value: 'warning',
  },
  {
    label: 'Error',
    value: 'error',
  },
];
