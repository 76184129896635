export const getCurrentApiUrl = (onlyDomain = false): string => {
  const url = window.location.origin;

  let baseURL = '';
  switch (true) {
    case url.includes('localhost'):
      baseURL = 'https://dev.elvo.io';
      break;
    case url.includes('dev.elvo.io'):
      baseURL = 'https://dev.elvo.io';
      break;
    case url.includes('demo.elvo.io'):
      baseURL = 'https://demo.elvo.io';
      break;
    case url.includes('volt.elvo.io'):
      baseURL = 'https://volt.elvo.io';
      break;
    case url.includes('v.elvo.io'):
      baseURL = 'http://v.elvo.io';
      break;
    default:
      baseURL = '';
  }
  return baseURL + (onlyDomain ? '' : '/api');
};
