import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const fetchClients = createAsyncThunk(
  'clients/fetchClients',
  async ({ lastName, firstName, email, phone, rfIds }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get('/clients');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    clients: [],
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.clients = action.payload;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default clientsSlice.reducer;
