import React from 'react';
import { Box, Typography, Drawer } from '@mui/material';

import logo from 'assets/images/logos/volt.png';
import logo_semaparc from 'assets/images/logos/semaparc-logo.svg';
import { LAYOUT } from 'utils/constants';
import packageJson from '../../../package.json';
import { SidebarMenu } from './SidebarMenu';

const getLogo = () => {
  const url = window.location.origin;
  let logoObj = {};
  switch (true) {
    case url.includes('localhost'):
      logoObj = { alt: 'VOLT', src: logo };
      break;
    case url.includes('dev.elvo.io'):
      logoObj = { alt: 'VOLT', src: logo };
      break;
    case url.includes('demo.elvo.io'):
      logoObj = { alt: 'VOLT', src: logo };
      break;
    case url.includes('volt.elvo.io'):
      logoObj = { alt: 'VOLT', src: logo };
      break;
    case url.includes('semaparc.elvo.io'):
      logoObj = { alt: 'SEMA PARC', src: logo_semaparc };
      break;
    default:
      logoObj = { alt: 'VOLT', src: logo };
  }
  return logoObj;
};

export function Sidebar({ openDrawer, hideDrawer, variant, isMobile }) {
  return (
    <Drawer
      open={!!openDrawer}
      variant={variant}
      onClose={hideDrawer}
      PaperProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          width: LAYOUT.SIDEBAR_WIDTH,
        },
      }}
      sx={{ zIndex: 1000 }}
    >
      <a href="/" style={{ textDecoration: 'none', textAlign: 'center' }}>
        <Box
          component="img"
          sx={{
            width: '40%',
            m: 0.7,
            mb: 2.8,
            filter: 'invert(.5) brightness(4)',
          }}
          alt={getLogo().alt}
          src={getLogo().src}
        />
        {/* <Box sx={{ textAlign: 'center', color: "white" }}>
        <Typography variant="h3" component="span" sx={{ fontWeight: 300 }}>VO</Typography>
        <Typography variant="h3" component="span" sx={{ fontWeight: 600 }}>LT</Typography>
      </Box> */}
      </a>
      <SidebarMenu hideDrawer={hideDrawer} isMobile={isMobile} />
      <Box
        sx={{
          textAlign: 'center',
          marginTop: 'auto',
        }}
      >
        <Typography
          variant="h6"
          component="span"
          sx={{
            fontWeight: 300,
            fontSize: '14px',
            color: '#afabb9',
          }}
        >
          Version {packageJson.version}
        </Typography>
      </Box>
    </Drawer>
  );
}
