import { CustomTheme } from '../../types';

const Switch = (theme: CustomTheme) => ({
  MuiSwitch: {
    styleOverrides: {
      root: {
        marginLeft: theme.spacing(0.375),
        '.MuiSwitch-switchBase': {
          color: theme.palette.grey[300],
        },
        '.MuiSwitch-switchBase.Mui-checked': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
});

export default Switch;
