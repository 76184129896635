import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';

export function FooterButtonSaveCancel({
  disabled = false,
  onSave,
  onCancel,
  sx = {},
  saveColor = 'primary',
  saveText = 'Save',
  cancelText = 'Cancel',
  cancelColor = 'primary',
}) {
  if (disabled) return null;

  return (
    <Box sx={[sx]}>
      {onCancel && (
        <Button onClick={onCancel} color={cancelColor} sx={{ mr: 2 }}>
          {cancelText}
        </Button>
      )}
      <Button variant="contained" color={saveColor} size="small" onClick={onSave}>
        {saveText}
      </Button>
    </Box>
  );
}
