import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { axiosRequest } from 'api/axiosRequest';
import { ApiCardFilterType, AsyncThunkConfig, Card, ReduxStatusEnum } from 'types';
import { CLIENT_ID_TENANT } from '../../../utils/models/client';

interface ICardsState {
  cards: Card[];
  rfidCards: Card[];
  status: ReduxStatusEnum;
  error: unknown | string | null;
}

const initialState: ICardsState = {
  cards: [],
  rfidCards: [],
  status: ReduxStatusEnum.IDLE,
  error: null,
};

export const fetchCards = createAsyncThunk<Card[], any, AsyncThunkConfig>(
  'cards/fetchCards',
  async ({ queryParameters }, { rejectWithValue }) => {
    try {
      const response = queryParameters
        ? await axiosRequest.get(`/cards${queryParameters}`)
        : await axiosRequest.get('/cards');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const clearCards = createAsyncThunk<Card[], void, AsyncThunkConfig>(
  'cards/clearCards',
  async (_, { rejectWithValue }) => {
    return [];
  }
);

export const fetchCardsByType = createAsyncThunk<
  { type: ApiCardFilterType; cards: Card[] },
  { type: ApiCardFilterType; clientId: string | undefined },
  AsyncThunkConfig
>('cards/fetchCardsByType', async ({ type, clientId }, { rejectWithValue }) => {
  try {
    let query = '';
    if (clientId?.length && clientId !== CLIENT_ID_TENANT) {
      query = `?clientId=${clientId}`;
    }

    const response = await axiosRequest.get(`/cards/by-type/${type}${query}`);
    return { type, cards: response.data };
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const cardsSlice = createSlice({
  name: 'cards',
  initialState: { ...initialState },
  reducers: {
    addCards: (state, action: PayloadAction<Card>) => {
      state.cards.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch all cards
      .addCase(fetchCards.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(fetchCards.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.cards = action.payload;
      })
      .addCase(fetchCards.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // clear all cards
      .addCase(clearCards.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(clearCards.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        state.cards = action.payload;
      })
      .addCase(clearCards.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      })

      // fetch cards by type
      .addCase(fetchCardsByType.pending, (state, action) => {
        state.status = ReduxStatusEnum.LOADING;
      })
      .addCase(fetchCardsByType.fulfilled, (state, action) => {
        state.status = ReduxStatusEnum.SUCCESS;
        if (action.payload.type === 'RFID') {
          state.rfidCards = action.payload.cards;
        }
      })
      .addCase(fetchCardsByType.rejected, (state, action) => {
        state.status = ReduxStatusEnum.FAILED;
        state.error = action.error.message;
      });
  },
});

export const { addCards } = cardsSlice.actions;

export default cardsSlice.reducer;
