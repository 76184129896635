import React from 'react';
import { Box, Typography } from '@mui/material';
import { ViewMoreText } from 'components/common/text/ViewMoreText';

// size = lg|md
export function ItemLabelValue({ label, value, size = 'lg', compressValue = false }) {
  const renderValue = value === '' || value === null || value === undefined ? '-' : value;

  const sizes = size === 'lg' ? ['14px', '18px'] : ['12px', '14px'];

  return (
    <Box>
      <Typography variant="subtitle3" component="div" sx={{ fontSize: sizes[0] }}>
        {label}
      </Typography>

      {compressValue && <ViewMoreText text={renderValue} variant="subtitle4" sx={{ fontSize: sizes[1] }} />}

      {!compressValue && (
        <Typography variant="subtitle4" component="div" sx={{ fontSize: sizes[1], wordWrap: 'break-word' }}>
          {renderValue}
        </Typography>
      )}
    </Box>
  );
}
