import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';
import { getStationSpecsByArticleNumber } from 'api/requests/stationSpecsRequest';

export const fetchArticleNumbers = createAsyncThunk(
  'articleNumbers/fetchArticleNumbers',
  async ({ manufacturedId }, { rejectWithValue }) => {
    const response = await axiosRequest.get(`/stationSpecs/articleNumbers`);
    return response.data.map((item) => {
      return {
        id: `${item}`,
        name: item,
      };
    });
  }
);

export const fetchArticleNumbersByManufacturerId = createAsyncThunk(
  'articleNumbers/fetchArticleNumbersByManufacturerId',
  async ({ manufacturedId }, { rejectWithValue }) => {
    const response = await axiosRequest.get(`/stationSpecs/articleNumbersByManufacturer/${manufacturedId}`);
    return response.data.map((item) => {
      return {
        id: `${item}`,
        name: item,
      };
    });
  }
);

export const fetchArticleNumbersByModelId = createAsyncThunk(
  'articleNumbers/fetchArticleNumbersByModelId',
  async ({ modelName, manufacturerName }, { rejectWithValue }) => {
    const response = await axiosRequest.get(
      `/stationSpecs/articleNumbersByManufacturerAndModel/${encodeURIComponent(manufacturerName)}/${encodeURIComponent(
        modelName
      )}`
    );
    return response.data.map((item) => {
      return {
        id: `${item}`,
        name: item,
      };
    });
  }
);

export const setSelectedArticleNumber = createAsyncThunk(
  'articleNumbers/setSelectedArticleNumber',
  async ({ articleNumber, onSuccess }, { rejectWithValue }) => {
    if (!articleNumber?.id) {
      return {
        selectedArticleNumber: {},
        stationSpecs: null,
      };
    }

    const response = await axiosRequest.get(`/stationSpecsByArticleNumber/${articleNumber.id}`);
    const specs = response.data ?? null;
    onSuccess && onSuccess(specs);

    return {
      selectedArticleNumber: { ...articleNumber },
      stationSpecs: specs,
    };
  }
);

export const fetchStationsSpecsByArticleNumber = createAsyncThunk(
  'articleNumbers/fetchStationsSpecsByArticleNumber',
  async ({ articleNumber }, { rejectWithValue }) => {
    const res = await getStationSpecsByArticleNumber(articleNumber);
    return res;
  }
);

const initialState = {
  articleNumbers: [],
  selectedArticleNumber: null,
  stationSpecs: null,
  status: STATUS_IDLE,
  error: null,
};
export const articleNumbersSlice = createSlice({
  name: 'articleNumbers',
  initialState: { ...initialState },
  reducers: {
    resetArticleNumbersAction: {
      reducer: (state) => {
        state.articleNumbers = initialState.articleNumbers;
        state.selectedArticleNumber = null;
        state.stationSpecs = null;
        state.status = initialState.status;
        state.error = initialState.error;
      },
    },
  },
  extraReducers: (builder) => {
    builder

      // fetchArticleNumbers
      .addCase(fetchArticleNumbers.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchArticleNumbers.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.articleNumbers = action.payload;
      })
      .addCase(fetchArticleNumbers.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // fetchArticleNumbersByManufacturerId
      .addCase(fetchArticleNumbersByModelId.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchArticleNumbersByModelId.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.articleNumbers = action.payload;
      })
      .addCase(fetchArticleNumbersByModelId.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // fetchArticleNumbersByManufacturerId
      .addCase(fetchArticleNumbersByManufacturerId.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchArticleNumbersByManufacturerId.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.articleNumbers = action.payload;
      })
      .addCase(fetchArticleNumbersByManufacturerId.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // setSelectedArticleNumber
      .addCase(setSelectedArticleNumber.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(setSelectedArticleNumber.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.selectedArticleNumber = action.payload.selectedArticleNumber;
        state.stationSpecs = action.payload.stationSpecs;
      })
      .addCase(setSelectedArticleNumber.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // fetchStationsSpecsByArticleNumber
      .addCase(fetchStationsSpecsByArticleNumber.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchStationsSpecsByArticleNumber.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.stationSpecs = action.payload;
      })
      .addCase(fetchStationsSpecsByArticleNumber.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetArticleNumbersAction } = articleNumbersSlice.actions;

export default articleNumbersSlice.reducer;
