export interface Card {
  active: boolean;
  billing: object | null;
  clientId: string;
  clientName: string;
  contractId: string;
  createdAt: string;
  details: CardDetails;
  groupId: string | null;
  id: string;
  status: string;
  type: CardType;
  updatedAt: string;
  validFrom: string;
  validTo: string;
}

export interface CardDetails {
  clientId: string;
  groupId: string;
  name: string;
}

export enum CardType {
  card = 'card',
  token = 'token',
}

export type ApiCardFilterType = 'RFID';
