import {
  DashboardOutlined as DashboardOutlinedIcon,
  CreditCardOutlined as CreditCardOutlinedIcon,
  EvStationOutlined as EvStationOutlinedIcon,
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  AccountTreeOutlined as AccountTreeOutlinedIcon,
  PeopleAltOutlined as PeopleAltOutlinedIcon,
  PhoneAndroidOutlined as PhoneAndroidOutlinedIcon,
  CorporateFareOutlined as CorporateFareOutlinedIcon,
  ContactSupportOutlined as ContactSupportOutlinedIcon,
  UpgradeOutlined as UpgradeOutlinedIcon,
  HistoryOutlined as HistoryOutlinedIcon,
  ShutterSpeedOutlined as ShutterSpeedOutlinedIcon,
  BadgeOutlined as BadgeOutlinedIcon,
  RoomOutlined as RoomOutlinedIcon,
  DynamicFormOutlined as DynamicFormOutlinedIcon,
  ListAltOutlined as ListAltOutlinedIcon,
} from '@mui/icons-material';

export const loadIcon = (iconName: string, fontSize = '20px', color = '#a5a5a5') => {
  const allIcons = {
    DashboardOutlinedIcon,
    CreditCardOutlinedIcon,
    EvStationOutlinedIcon,
    AccountCircleOutlinedIcon,
    AccountTreeOutlinedIcon,
    PeopleAltOutlinedIcon,
    PhoneAndroidOutlinedIcon,
    CorporateFareOutlinedIcon,
    ContactSupportOutlinedIcon,
    UpgradeOutlinedIcon,
    HistoryOutlinedIcon,
    ShutterSpeedOutlinedIcon,
    BadgeOutlinedIcon,
    RoomOutlinedIcon,
    DynamicFormOutlinedIcon,
    ListAltOutlinedIcon,
  };

  const CustomIcon = allIcons[iconName as keyof typeof allIcons];
  if (!CustomIcon) {
    return null;
  }

  return <CustomIcon sx={{ fontSize, color }} />;
};
