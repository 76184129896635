import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const fetchStationsModels = createAsyncThunk(
  'stationsModels/fetchStationsModels',
  async ({}, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.get('/stationSpecs');
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteStationModel = createAsyncThunk(
  'stationsModels/deleteStationModel',
  async ({ stationModelId, onSuccessCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.delete(`/stationSpecs/${stationModelId}`);
      onSuccessCallback && onSuccessCallback();
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const stationsModelsSlice = createSlice({
  name: 'stationsModels',
  initialState: {
    stationsModels: [],
    status: STATUS_IDLE,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      // fetch stations models
      .addCase(fetchStationsModels.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchStationsModels.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.stationsModels = action.payload;
      })
      .addCase(fetchStationsModels.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export default stationsModelsSlice.reducer;
