import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { useDispatch } from 'react-redux';
import { getDifferenceBetweenDatesAsString } from 'utils/datetime';
import { loadIcon } from 'utils/icons';
import { setNotificationRead } from 'redux/slices/notificationsSlice';

function NotificationItem({ notification, isLastItem = false }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToNotificationAction = () => {
    if (!notification.read) {
      dispatch(setNotificationRead({ notificationId: notification?.id }));
    }
    if (notification?.link) {
      navigate(notification.link);
    } else if (notification?.stationId) {
      navigate(`/stations/${notification?.stationId}/details`);
    } else if (notification?.cardId) {
      navigate(`/authorizations/${notification?.cardId}/details`);
    }
  };

  return (
    <>
      <ListItem
        alignItems="center"
        sx={{
          borderRadius: 1,
          justifyContent: 'space-between',
          cursor: 'pointer',
          marginTop: '8px',
          marginBottom: '8px',
          width: 'calc(100% - 2px)',
          '&:hover': { backgroundColor: 'rgba(0,0,0,0.05)' },
        }}
        onClick={goToNotificationAction}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <ListItemAvatar sx={{ marginTop: '8px' }}>{loadIcon('EvStationOutlinedIcon', '32px')}</ListItemAvatar>
          <ListItemText
            primary={
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                {notification?.status?.toLowerCase() === 'error' && (
                  <ErrorIcon sx={{ color: 'red', fontSize: '16px', marginRight: '2px' }} />
                )}

                {notification?.status?.toLowerCase() === 'warning' && (
                  <WarningIcon
                    sx={{
                      color: 'orange',
                      fontSize: '16px',
                      marginRight: '2px',
                    }}
                  />
                )}

                <Typography
                  component="span"
                  variant="body2"
                  fontWeight="bold"
                  marginBottom="8px"
                  color="text.primary"
                  sx={{ display: 'block', marginBottom: 0 }}
                >
                  Station: {notification.stationId}
                </Typography>
              </Box>
            }
            secondary={
              <Box>
                <Typography
                  sx={{ display: 'block' }}
                  component="span"
                  variant="body2"
                  paddingBottom="8px"
                  color="text.primary"
                >
                  {notification.message}
                </Typography>
                <Typography
                  sx={{ display: 'block', opacity: '0.7' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {getDifferenceBetweenDatesAsString(new Date(notification.timestamp), new Date())}
                </Typography>
              </Box>
            }
          />
        </Box>
        <Box>
          {!notification.read ? <Avatar sx={{ bgcolor: '#388e3c', width: '12px', height: '12px' }}> </Avatar> : null}
        </Box>
      </ListItem>

      <Divider variant="fullWidth" component="li" sx={{ opacity: '0.5', marginLeft: '16px', marginRight: '16px' }} />
    </>
  );
}
export default NotificationItem;
