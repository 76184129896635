export function capitalizeFirstLetter(string) {
  if (!string?.length) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function buildNavTree(menu, location) {
  return menu?.map((menuGroup) => {
    if (Array.isArray(menuGroup?.children)) {
      const groupChildren = menuGroup.children.map((menuSubgroup) => {
        let selected = false;

        if (Array.isArray(menuSubgroup.children)) {
          let open = false;

          const subGroupChildren = menuSubgroup.children.map((menuItem) => {
            if (isInPathName(menuItem.href, location)) {
              open = true;
              return { ...menuItem, selected: true };
            }

            return menuItem;
          });

          return { ...menuSubgroup, open, children: subGroupChildren };
        }

        if (isInPathName(menuSubgroup.href, location)) {
          selected = true;
        }

        return { ...menuSubgroup, selected };
      });
      return { ...menuGroup, children: groupChildren };
    }

    return menuGroup;
  });
}

const isInPathName = (href, location) => {
  return (
    href === location.pathname ||
    (location.pathname.split('/').length > 3 && href.split('/').length > 2 && location.pathname.includes(href))
  );
};

export const stringCompare = (a, b) => a.localeCompare(b);
