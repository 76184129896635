import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/../system';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useSelector } from 'react-redux';
import { LAST_AUTH_PATH, saveToLocalStorage } from 'utils';
import { Navbar } from 'layout/Navbar';
import { Sidebar } from 'layout/Sidebar';
import { theme } from 'themes/defaultTheme';

const DashboardLayoutRoot = styled('div')(({}) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const DashboardLayoutContentWrapper = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingLeft: 0,
  paddingTop: '64px',
  backgroundColor: '#ebebeb',
}));

const DashboardLayoutContent = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  overflow: 'auto',
});

export default function MainLayout() {
  const [openDrawer, setOpenDrawer] = useState(null);
  const [isMobile, setIsMobile] = useState(null);
  const [variant, setVariant] = useState('temporary');

  const nativeTheme = useTheme();
  const matches = useMediaQuery(nativeTheme.breakpoints.up('md'));

  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.token && !window.location.pathname.includes('/auth/')) {
      const path = window.location.pathname + window.location.search;
      saveToLocalStorage(LAST_AUTH_PATH, path);
      navigate('/auth/login');
    }
  }, [user?.token]);

  useEffect(() => {
    if (matches) {
      setOpenDrawer(true);
      setIsMobile(false);
      setVariant('persistent');
    } else {
      setOpenDrawer(false);
      setIsMobile(true);
      setVariant('temporary');
    }
  }, [matches]);

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const hideDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <DashboardLayoutRoot>
      <Navbar openDrawer={openDrawer} isMobile={isMobile} showDrawer={showDrawer} hideDrawer={hideDrawer} />
      <Sidebar openDrawer={openDrawer} hideDrawer={hideDrawer} variant={variant} isMobile={isMobile} />
      <DashboardLayoutContentWrapper style={{ paddingLeft: openDrawer && !isMobile ? 260 : 0 }}>
        <DashboardLayoutContent>
          <Outlet />
        </DashboardLayoutContent>
      </DashboardLayoutContentWrapper>
    </DashboardLayoutRoot>
  );
}
