import React from 'react';
import { Box, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { modalBoxShadowStyle } from 'themes/defaultTheme';
import { hideModal } from 'redux/slices/ui/modalSlice';
import { FooterButtonSaveCancel } from '../button/FooterButtonSaveCancel';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  maxWidth: '90%',
  pl: 4,
  pr: 1,
  pt: 4,
  pb: 4,
  maxHeight: '95vh',
  ...modalBoxShadowStyle,
};

export function CustomModal({}) {
  const { isVisible, title, onClose, getContent, onSubmit, buttonColor, buttonText } = useSelector(
    (state) => state.modal
  );
  const dispatch = useDispatch();

  const onLocalClose = () => {
    dispatch(hideModal());
    onClose && onClose();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      open={!!isVisible}
      onClose={onLocalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            paddingRight: 3,
            display: 'block',
            position: 'relative',
            overflowY: 'auto',
            maxHeight: 'calc(95vh - 4rem)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4" component="h2">
              {title}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onLocalClose}
              sx={{
                mr: -1.5,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>{getContent && getContent()}</Box>
          <FooterButtonSaveCancel
            sx={{ textAlign: 'right' }}
            onCancel={onLocalClose}
            onSave={onSubmit}
            saveText={buttonText}
            saveColor={buttonColor}
          />
        </Box>
      </Box>
    </Modal>
  );
}
