import React from 'react';
import { MdOutlineEditNote } from 'react-icons/md';
import { RiFileExcel2Line } from 'react-icons/ri';
import { theme } from 'themes/defaultTheme';

export const addTableOptions = (isTenant) => ({
  cards: [
    {
      id: 'c1',
      title: 'Import single card',
      description:
        // eslint-disable-next-line max-len
        'Choose this option if you want to add one single card and fill in manually all the information about the card.',
      isVisible: isTenant,
      link: '/authorizations/import/single',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
    {
      id: 'c2',
      title: 'Import multiple cards',
      description:
        'Choose this option if you have all the data in an excel file and you want to import multipple records.',
      isVisible: isTenant,
      link: '/authorizations/import/multiple',
      icon: <RiFileExcel2Line color={theme.palette.customColors.excelGreen} fontSize={32} />,
    },
    {
      id: 'c3',
      title: 'Activate multiple cards',
      description:
        'Choose this option if you have all the data in an excel file and you want to activate multiple records.',
      isVisible: true,
      link: '/authorizations/activate/multiple',
      icon: <RiFileExcel2Line color={theme.palette.customColors.excelGreen} fontSize={32} />,
    },
  ],
  stations: [
    {
      id: 's1',
      title: 'Import single station',
      description:
        // eslint-disable-next-line max-len
        'Choose this option if you want to add one single station and fill in manually all the information about the station.',
      isVisible: isTenant,
      link: '/stations/import/single',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
    {
      id: 's2',
      title: 'Import multiple stations',
      description:
        'Choose this option if you have all the data in an excel file and you want to import multipple records.',
      isVisible: isTenant,
      link: '/stations/import/multiple',
      icon: <RiFileExcel2Line color={theme.palette.customColors.excelGreen} fontSize={32} />,
    },
    {
      id: 's3',
      title: 'Activate multiple stations',
      description:
        'Choose this option if you have all the data in an excel file and you want to activate multiple records.',
      isVisible: true,
      link: '/stations/activate/multiple',
      icon: <RiFileExcel2Line color={theme.palette.customColors.excelGreen} fontSize={32} />,
    },
  ],
  stationsModels: [
    {
      id: 'sm1',
      title: 'Add station model',
      description: 'Choose this option if you want to add one station model.',
      isVisible: true,
      link: '/stations/models/create',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  stationsLocations: [
    {
      id: 'sl1',
      title: 'Add station location',
      description: 'Choose this option if you want to add one station location.',
      isVisible: true,
      link: '/stations/locations/create',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  stationsChargingProfiles: [
    {
      id: 'scp1',
      title: 'Create charging profile',
      description: 'Choose this option if you want to create one charging profile.',
      isVisible: true,
      link: '/stations/charging-profiles/create',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  stationsSmartCharging: [
    {
      id: 'ssc1',
      title: 'Create smart charging',
      description: 'Choose this option if you want to create one smart charging group.',
      isVisible: true,
      link: '/stations/smart-charging/create',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  clientsUsers: [
    {
      id: 'cs1',
      title: 'Add client user',
      description: 'Choose this option if you want to add one user for one client.',
      isVisible: true,
      link: '/clients/users/add-client-user',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  companyUsers: [
    {
      id: 'u1',
      title: 'Add user',
      description: 'Choose this option if you want to add one user.',
      isVisible: true,
      link: '/company/users/add-users',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  drivers: [
    {
      id: 'd1',
      title: 'Add driver',
      description: 'Choose this option if you want to add one driver.',
      isVisible: true,
      link: '/drivers/register/manual',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
  clients: [
    {
      id: 'cl1',
      title: 'Add individual client',
      description: 'Choose this option if you want to add one individual client.',
      isVisible: true,
      link: '/clients/register/individual',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
    {
      id: 'cl2',
      title: 'Add company client',
      description: 'Choose this option if you want to add one company client.',
      isVisible: true,
      link: '/clients/register/company',
      icon: <MdOutlineEditNote color={theme.palette.primary.main} fontSize={32} />,
    },
  ],
});
