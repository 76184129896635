import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const fetchModels = createAsyncThunk('models/fetchModels', async ({ manufacturerName }, { rejectWithValue }) => {
  const response = await axiosRequest.get(`/stationSpecs/modelsByManufacturer/${encodeURIComponent(manufacturerName)}`);

  return response.data.map((item) => {
    return {
      id: `${item}`,
      name: item,
    };
  });
});

export const createModel = createAsyncThunk(
  'models/createModel',
  async ({ details, network, image, connectors, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      const body = {
        ...details,
        maxCurrent: undefined,
        ...network,
        image,
        connectors,
        details: {
          maxCurrent: details.maxCurrent,
        },
      };

      const response = await axiosRequest.post('/stationSpecs', body);

      onSuccessCallback && onSuccessCallback();

      return response.data;
    } catch (e) {
      onErrorCallback && onErrorCallback();

      return rejectWithValue(e);
    }
  }
);

export const updateModel = createAsyncThunk(
  'models/updateModel',
  async ({ id, details, network, image, connectors, onSuccessCallback, onErrorCallback }, { rejectWithValue }) => {
    try {
      const body = {
        ...details,
        ...network,
        maxCurrent: undefined,
        image,
        connectors,
        details: {
          maxCurrent: details.maxCurrent,
        },
      };

      const response = await axiosRequest.put(`/stationSpecs/${id}`, body);

      onSuccessCallback && onSuccessCallback();

      return response.data;
    } catch (e) {
      onErrorCallback && onErrorCallback();

      return rejectWithValue(e);
    }
  }
);

export const modelsSlice = createSlice({
  name: 'models',
  initialState: {
    models: [],
    model: {},
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {
    selectModel: {
      reducer: (state, action) => {
        state.model = {
          id: action.payload.name,
          name: action.payload.name,
        };
      },
    },
    resetModel: {
      reducer: (state, action) => {
        state.model = {};
      },
    },
  },
  extraReducers: (builder) => {
    builder

      // fetch models
      .addCase(fetchModels.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchModels.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.models = action.payload;
      })
      .addCase(fetchModels.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // create model
      .addCase(createModel.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(createModel.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(createModel.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      })

      // update model
      .addCase(updateModel.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(updateModel.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
      })
      .addCase(updateModel.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { selectModel, resetModel } = modelsSlice.actions;

export default modelsSlice.reducer;
