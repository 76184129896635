import React, { useState } from 'react';
import { Avatar, Button, ListItemAvatar, ListItemText, Menu, MenuItem, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

import { addTableOptions } from 'utils/DataGrid';

export function ImportActivateFromTable({ isTenant, itemType }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const handleClick = (event) => {
    const menuOptions = addTableOptions(isTenant)[itemType].filter((el) => el.isVisible === true);
    if (menuOptions.length === 1) {
      navigate(menuOptions[0].link);
    } else if (menuOptions.length > 1) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => setAnchorEl(null);

  const handleRedirect = (link) => navigate(link);

  return (
    <>
      <Button
        id="addTableButton"
        aria-controls={open ? 'addTableMenu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        sx={{
          '& .MuiButton-startIcon': {
            mx: 0,
            mb: 0.25,
          },
          '& .MuiSvgIcon-root': {
            color: 'common.white',
            fontSize: 21,
          },
          minWidth: 42,
          maxWidth: 42,
          height: 42,
          borderRadius: '50%',
        }}
        onClick={handleClick}
        startIcon={<AddIcon />}
      />
      <Menu
        id="addTableMenu"
        MenuListProps={{
          'aria-labelledby': 'addTableButton',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              maxWidth: 400,
              maxHeight: 500,
            },
          },
        }}
        sx={{ mt: 2 }}
      >
        {addTableOptions(isTenant)[itemType].map((el) => {
          if (el.isVisible) {
            return (
              <MenuItem
                key={el.id}
                onClick={() => {
                  handleClose();
                  handleRedirect(el.link);
                }}
                disableRipple
                sx={{ m: 2, py: 2, alignItems: 'flex-start' }}
              >
                <ListItemAvatar sx={{ mr: 2 }}>
                  <Avatar
                    component={Paper}
                    elevation={2}
                    sx={{ bgcolor: 'white', width: 50, height: 50 }}
                    variant="rounded"
                  >
                    {el.icon}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={el.title}
                  primaryTypographyProps={{
                    style: { fontWeight: 'bold', color: '#000000' },
                  }}
                  secondary={el.description}
                  secondaryTypographyProps={{
                    style: {
                      whiteSpace: 'normal',
                      fontSize: 12,
                      color: '#000000',
                    },
                  }}
                />
              </MenuItem>
            );
          }

          return null;
        })}
      </Menu>
    </>
  );
}
