import React, { lazy } from 'react';

import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

//
// PAGES COMMON
//

const Dashboard = Loadable(lazy(() => import('pages/Dashboard')));

const UnderDevelopment = Loadable(lazy(() => import('components/common/UnderDevelopment')));

//
// PAGES CARDS
//

const CardsDashboard = Loadable(lazy(() => import('pages/Cards/Dashboard')));

const ImportCardsChoose = Loadable(lazy(() => import('pages/Cards/ImportCards/ImportCardsChoose')));

const ImportCardsMultiple = Loadable(lazy(() => import('pages/Cards/ImportCards/Multiple/ImportCardsMultiple')));

const ImportSingleCardPage = Loadable(lazy(() => import('pages/Cards/ImportCards/Single/ImportSingleCardPage')));

const RegisterCardsChoose = Loadable(lazy(() => import('pages/Cards/RegisterCard/RegisterCardsChoose')));

const RegisterCardsMultiple = Loadable(lazy(() => import('pages/Cards/RegisterCard/Multiple/RegisterCardsMultiple')));

const RegisterSingleCardPage = Loadable(lazy(() => import('pages/Cards/RegisterCard/Single/RegisterSingleCardPage')));

const CardChargingHistory = Loadable(lazy(() => import('pages/Cards/CardTabs/CardChargingHistory')));
const CardDashboard = Loadable(lazy(() => import('pages/Cards/CardTabs/CardDashboard')));
const CardDetails = Loadable(lazy(() => import('pages/Cards/CardTabs/CardDetails/CardDetails')));

const CardTabs = Loadable(lazy(() => import('pages/Cards/CardTabs/CardTabs')));

//
// PAGES STATIONS
//

const StationsMapPage = Loadable(lazy(() => import('pages/Stations/StationsMap/StationsMapPage')));

const StationChargingHistory = Loadable(lazy(() => import('pages/Stations/StationTabs/StationChargingHistoryTab')));

const StationsChargingSessions = Loadable(lazy(() => import('pages/Stations/StationTabs/StationsChargingSessions')));

const StationConnectors = Loadable(lazy(() => import('pages/Stations/StationTabs/StationConnectorsTab')));

const StationDashboard = Loadable(lazy(() => import('pages/Stations/StationTabs/StationDashboard')));

const StationLocation = Loadable(lazy(() => import('pages/Stations/StationTabs/StationLocationTab')));

const StationLogs = Loadable(lazy(() => import('pages/Stations/StationTabs/StationLogsTab')));

const StationDetails = Loadable(lazy(() => import('pages/Stations/StationTabs/StationDetails/StationDetailsTab')));

const StationPricing = Loadable(lazy(() => import('pages/Stations/StationTabs/StationPricing/StationPricingTab')));

const StationSettings = Loadable(lazy(() => import('pages/Stations/StationTabs/Settings/StationSettingsTab')));

const StationTabs = Loadable(lazy(() => import('pages/Stations/StationTabs/StationTabs')));

const StationConfiguration = Loadable(lazy(() => import('pages/Stations/StationTabs/StationConfigurationTab')));

const StationDiagnosticsTab = Loadable(lazy(() => import('pages/Stations/StationTabs/StationDiagnosticsTab')));

const StationTerminal = Loadable(lazy(() => import('pages/Stations/StationTabs/StationTerminalTab')));

const RegisterStationsMultiple = Loadable(
  lazy(() => import('pages/Stations/RegisterStation/Multiple/RegisterStationsMultiple'))
);

const RegisterStationPage = Loadable(lazy(() => import('pages/Stations/RegisterStation/Single/RegisterStationPage')));

const RegisterStationsChoose = Loadable(lazy(() => import('pages/Stations/RegisterStation/RegisterStationsChoose')));

const ImportStationChoose = Loadable(lazy(() => import('pages/Stations/ImportStation/ImportStationChoose')));

const ImportSingleStationPage = Loadable(
  lazy(() => import('pages/Stations/ImportStation/Single/ImportSingleStationPage'))
);

const ImportStationMultiple = Loadable(
  lazy(() => import('pages/Stations/ImportStation/Multiple/ImportStationMultiple'))
);

const StationsDashboard = Loadable(lazy(() => import('pages/Stations/Dashboard')));

const StationsModelsConnectors = Loadable(lazy(() => import('pages/Stations/Models')));

const CreateModelPage = Loadable(lazy(() => import('pages/Stations/Models/Create/CreateModelPage')));

const StationsLocations = Loadable(lazy(() => import('pages/Stations/Locations')));

const CreateLocationPage = Loadable(lazy(() => import('pages/Stations/Locations/Create/CreateLocationPage')));

const UpdateLocationPage = Loadable(lazy(() => import('pages/Stations/Locations/Update/UpdateLocationPage')));

const CreateChargingProfilePage = Loadable(
  lazy(() => import('pages/Stations/ChargingProfiles/Create/CreateChargingProfilePage'))
);

const ChargingProfiles = Loadable(lazy(() => import('pages/Stations/ChargingProfiles')));

// smart charging
const SmartCharging = Loadable(lazy(() => import('pages/Stations/SmartCharging')));
const CreateEditSmartChargingPage = Loadable(
  lazy(() => import('pages/Stations/SmartCharging/Create/CreateEditSmartChargingPage'))
);
const SmartChargingDetails = Loadable(lazy(() => import('pages/Stations/SmartCharging/Details/SmartChargingDetails')));

// visibility profile
const CreatePricingProfilePage = Loadable(
  lazy(() => import('pages/Stations/PricingProfiles/CreatePricingProfilePage'))
);

//
// PAGES DRIVER
//

const DriversDashboard = Loadable(lazy(() => import('pages/Drivers/Dashboard')));
const InviteDriverPage = Loadable(lazy(() => import('pages/Drivers/RegisterDriver/InviteDriverPage')));
const RegisterDriverManualPage = Loadable(lazy(() => import('pages/Drivers/RegisterDriver/RegisterDriverManualPage')));
const RegisterDriverPage = Loadable(lazy(() => import('pages/Drivers/RegisterDriver/RegisterDriverPage')));
const DriverTabs = Loadable(lazy(() => import('pages/Drivers/DriverTabs/DriverTabs')));
const DriverDetailsTab = Loadable(lazy(() => import('pages/Drivers/DriverTabs/DriverDetailsTab')));
const DriverBillingTab = Loadable(lazy(() => import('pages/Drivers/DriverTabs/DriverBillingTab')));
const DriverChargingHistoryTab = Loadable(lazy(() => import('pages/Drivers/DriverTabs/DriverChargingHistoryTab')));
const DriverAuthorizationsTab = Loadable(lazy(() => import('pages/Drivers/DriverTabs/DriverAuthorizationsTab')));

const DriversBilling = Loadable(lazy(() => import('pages/Billing/DriversBilling')));
const ClientsBilling = Loadable(lazy(() => import('pages/Billing/ClientsBilling')));
//
// PAGES CLIENTS
//

const UserTabs = Loadable(lazy(() => import('pages/Clients/Users/UserTabs/UserTabs')));

const UserDetails = Loadable(lazy(() => import('pages/Clients/Users/UserTabs/UserDetails')));

const ClientsDashboard = Loadable(lazy(() => import('pages/Clients/Dashboard')));

const RegisterClientPage = Loadable(lazy(() => import('pages/Clients/RegisterClient/RegisterClientPage')));

const RegisterClientIndividualPage = Loadable(
  lazy(() => import('pages/Clients/RegisterClient/RegisterClientIndividual/RegisterClientIndividualPage'))
);

const RegisterClientCompanyPage = Loadable(
  lazy(() => import('pages/Clients/RegisterClient/RegisterClientCompany/RegisterClientCompanyPage'))
);

const InviteClientPage = Loadable(lazy(() => import('pages/Clients/RegisterClient/Invite/InviteClientPage')));

const ClientsUsers = Loadable(lazy(() => import('pages/Clients/Users/Dashboard')));

const AddUser = Loadable(lazy(() => import('pages/Clients/Users/AddUser')));

const ClientTabs = Loadable(lazy(() => import('pages/Clients/ClientTabs/ClientTabs')));
const ClientDetailsTab = Loadable(lazy(() => import('pages/Clients/ClientTabs/ClientDetails/ClientDetailsTab')));
const ClientStations = Loadable(lazy(() => import('pages/Clients/ClientTabs/ClientStations')));
const ClientAuthorizations = Loadable(lazy(() => import('pages/Clients/ClientTabs/ClientAuthorizations')));
const ClientDrivers = Loadable(lazy(() => import('pages/Clients/ClientTabs/ClientDrivers')));
const ClientUsers = Loadable(lazy(() => import('pages/Clients/ClientTabs/ClientUsers')));
const ClientBillingPlanTab = Loadable(lazy(() => import('pages/Clients/ClientTabs/ClientBillingPlanTab')));

//
// PAGES COMPANY
//

const CompanyUsers = Loadable(lazy(() => import('pages/Company/Users/Dashboard')));

const AddUserPage = Loadable(lazy(() => import('pages/Company/Users/AddUser/AddUserPage')));

const CompanyUserDetails = Loadable(lazy(() => import('pages/Company/Users/UserTabs/CompanyUserDetails')));

const CompanyUserTabs = Loadable(lazy(() => import('pages/Company/Users/UserTabs/CompanyUserTabs')));

const GeneralCompanyPage = Loadable(lazy(() => import('pages/Company/GeneralCompanyPage')));

const CompanyBillingTabs = Loadable(lazy(() => import('pages/Company/Billing/CompanyBillingTabs')));
const CompanyBillingDetailsTab = Loadable(lazy(() => import('pages/Company/Billing/CompanyBillingDetailsTab')));
const CompanyBillingPlanTab = Loadable(lazy(() => import('pages/Company/Billing/CompanyBillingPlanTab')));

//
// PAGES ACCOUNT
//

const MyProfilePage = Loadable(lazy(() => import('pages/Account/MyProfile/MyProfilePage')));

//
// MAIN ROUTES
//

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      index: true,
      element: <Dashboard />,
    },
    {
      path: 'dashboard',
      element: <Dashboard />,
    },
    {
      path: 'authorizations',
      element: <CardsDashboard />,
    },
    {
      path: 'stations',
      element: <StationsDashboard />,
    },
    {
      path: 'stations/charging-profiles',
      element: <ChargingProfiles />,
    },
    {
      path: 'stations/smart-charging',
      element: <SmartCharging />,
    },
    {
      path: 'stations/smart-charging/create',
      element: <CreateEditSmartChargingPage />,
    },
    {
      path: 'stations/smart-charging/:id',
      element: <CreateEditSmartChargingPage />,
    },
    {
      path: 'stations/smart-charging/:id/details',
      element: <SmartChargingDetails />,
    },
    {
      path: 'stations/charging-profiles/create',
      element: <CreateChargingProfilePage />,
    },
    {
      path: 'stations/charging-profiles/:id',
      element: <CreateChargingProfilePage />,
    },
    {
      path: 'stations/pricing-profiles/create',
      element: <CreatePricingProfilePage />,
    },
    {
      path: 'stations/pricing-profile',
      element: <UnderDevelopment />,
    },
    {
      path: 'stations/map',
      element: <StationsMapPage />,
    },
    {
      path: 'stations/models',
      element: <StationsModelsConnectors />,
    },
    {
      path: 'stations/models/create',
      element: <CreateModelPage />,
    },
    {
      path: 'stations/models/:id',
      element: <CreateModelPage />,
    },
    {
      path: 'stations/locations',
      element: <StationsLocations />,
    },
    {
      path: 'stations/locations/create',
      element: <CreateLocationPage />,
    },
    {
      path: 'stations/locations/:id',
      element: <UpdateLocationPage />,
    },
    {
      path: '/subsidiaries',
      element: <UnderDevelopment />,
    },
    {
      path: '/subsidiaries/create',
      element: <UnderDevelopment />,
    },
    {
      path: '/charging-apps',
      element: <UnderDevelopment />,
    },
    {
      path: '/company/users',
      element: <CompanyUsers />,
    },
    {
      path: '/company/users/add-users',
      element: <AddUserPage />,
    },
    {
      path: '/company/users/:id',
      element: <CompanyUserTabs />,
      children: [
        {
          index: true,
          element: <CompanyUserDetails />,
        },
      ],
    },
    {
      path: '/company/roles',
      element: <UnderDevelopment />,
    },
    {
      path: '/company/customization',
      element: <UnderDevelopment />,
    },
    {
      path: '/support',
      element: <UnderDevelopment />,
    },
    {
      path: '/upgrade',
      element: <UnderDevelopment />,
    },
    {
      path: 'stations/:id',
      element: <StationTabs />,
      children: [
        // {
        //   index: true,
        //   element: <StationDashboard />,
        // },
        {
          index: true,
          element: <StationConnectors />,
        },
        {
          path: 'details',
          element: <StationDetails />,
        },
        {
          path: 'pricing',
          element: <StationPricing />,
        },
        {
          path: 'location',
          element: <StationLocation />,
        },
        {
          path: 'settings',
          element: <StationSettings />,
        },
        {
          path: 'charging-history',
          element: <StationChargingHistory />,
        },
        {
          path: 'logs',
          element: <StationLogs />,
        },
        {
          path: 'configuration',
          element: <StationConfiguration />,
        },
        {
          path: 'diagnostics',
          element: <StationDiagnosticsTab />,
        },
        {
          path: 'terminal',
          element: <StationTerminal />,
        },
      ],
    },
    {
      path: 'stations/activate',
      element: <RegisterStationsChoose />,
    },
    {
      path: 'stations/activate/single',
      element: <RegisterStationPage />,
    },
    {
      path: 'stations/activate/multiple',
      element: <RegisterStationsMultiple />,
    },
    {
      path: 'stations/import',
      element: <ImportStationChoose />,
    },
    {
      path: 'stations/import/single',
      element: <ImportSingleStationPage />,
    },
    {
      path: 'stations/import/multiple',
      element: <ImportStationMultiple />,
    },
    {
      path: 'stations/charging-sessions',
      element: <StationsChargingSessions />,
    },

    // CARDS
    {
      path: '/authorizations/import',
      element: <ImportCardsChoose />,
    },
    {
      path: '/authorizations/import/single',
      element: <ImportSingleCardPage />,
    },
    {
      path: '/authorizations/import/multiple',
      element: <ImportCardsMultiple />,
    },
    {
      path: '/authorizations/activate',
      element: <RegisterCardsChoose />,
    },
    {
      path: '/authorizations/activate/single',
      element: <RegisterSingleCardPage />,
    },
    {
      path: '/authorizations/activate/multiple',
      element: <RegisterCardsMultiple />,
    },
    {
      path: '/authorizations/:id',
      element: <CardTabs />,
      children: [
        // {
        //   index: true,
        //   element: <CardDashboard />,
        // },
        {
          index: true,
          element: <CardDetails />,
        },
        {
          path: 'charging-history',
          element: <CardChargingHistory />,
        },
      ],
    },
    {
      path: '/company',
      element: <GeneralCompanyPage />,
    },
    {
      path: '/company/billing',
      element: <CompanyBillingTabs />,
      children: [
        {
          index: true,
          element: <CompanyBillingDetailsTab />,
        },
        {
          path: 'plan',
          element: <CompanyBillingPlanTab />,
        },
      ],
    },
    {
      path: '/clients',
      element: <ClientsDashboard />,
    },
    {
      path: '/clients/register',
      element: <RegisterClientPage />,
    },
    {
      path: '/clients/register/individual',
      element: <RegisterClientIndividualPage />,
    },
    {
      path: '/clients/register/company',
      element: <RegisterClientCompanyPage />,
    },
    {
      path: '/clients/register/invite/:type',
      element: <InviteClientPage />,
    },
    {
      path: '/clients/users',
      element: <ClientsUsers />,
    },
    {
      path: '/clients/users/add-client-user',
      element: <AddUser />,
    },
    {
      path: '/clients/users/:id',
      element: <UserTabs />,
      children: [
        {
          index: true,
          element: <UserDetails />,
        },
      ],
    },
    {
      path: '/clients/:id',
      element: <ClientTabs />,
      children: [
        {
          index: true,
          element: <ClientDetailsTab />,
        },
        {
          path: 'stations',
          element: <ClientStations />,
        },
        {
          path: 'authorizations',
          element: <ClientAuthorizations />,
        },
        {
          path: 'drivers',
          element: <ClientDrivers />,
        },
        {
          path: 'users',
          element: <ClientUsers />,
        },
        {
          path: 'billing-plan',
          element: <ClientBillingPlanTab />,
        },
      ],
    },
    {
      path: '/drivers',
      element: <DriversDashboard />,
    },
    {
      path: '/drivers/:id',
      element: <DriverTabs />,
      children: [
        {
          index: true,
          element: <DriverDetailsTab />,
        },
        {
          path: 'billing',
          element: <DriverBillingTab />,
        },
        {
          path: 'charging-history',
          element: <DriverChargingHistoryTab />,
        },
        {
          path: 'authorizations',
          element: <DriverAuthorizationsTab />,
        },
      ],
    },
    {
      path: '/drivers/register',
      element: <RegisterDriverPage />,
    },
    {
      path: '/drivers/register/manual',
      element: <RegisterDriverManualPage />,
    },
    {
      path: '/drivers/register/invite',
      element: <InviteDriverPage />,
    },
    {
      path: '/billing-drivers',
      element: <DriversBilling />,
    },
    {
      path: '/billing-clients',
      element: <ClientsBilling />,
    },
    {
      path: '/my-profile',
      element: <MyProfilePage />,
    },
  ],
};
export default MainRoutes;
