import { createSlice } from '@reduxjs/toolkit';
import { getConnectorInitialState } from 'utils/models/connector';

const initialState = {
  details: {
    manufacturer: '',
    model: '',
    articleNumber: '',
    maxCurrent: '',
  },
  networkDetails: {
    wiFiSupported: false,
    gsmSupported: false,
  },
  connectors: [{ ...getConnectorInitialState(true) }],
  image: null,
  activeStep: 0,
  isUpdated: false,
  isNewManufacturer: false,
  id: null,
};

export const createModelStepSlice = createSlice({
  name: 'createModelStep',
  initialState: {
    data: initialState,
  },
  reducers: {
    setToUpdateModel: {
      reducer: (state, action) => {
        state.data.isUpdated = true;
        state.data.id = action.payload.id;
      },
    },
    setDetailsModelStep: {
      reducer: (state, action) => {
        state.data.details = {
          ...state.data.details,
          ...action.payload,
        };
      },
    },
    setIsNewManufacturerModelStep: {
      reducer: (state, action) => {
        state.data.isNewManufacturer = action.payload;
      },
    },
    setNetworkModelStep: {
      reducer: (state, action) => {
        state.data.networkDetails = {
          ...state.data.networkDetails,
          ...action.payload,
        };
      },
    },
    setConnectorModelStep: {
      reducer: (state, action) => {
        state.data.connectors = action.payload;
      },
    },
    setImageCreateModelStep: {
      reducer: (state, action) => {
        state.data.image = action.payload.image;
      },
    },
    resetModelCreateStep: {
      reducer: (state) => {
        state.data = initialState;
      },
    },
    increaseModelCreateStep: {
      reducer: (state, action) => {
        const stepSize = 1;
        state.data.activeStep += stepSize;
      },
    },
    decreaseModelCreateStep: {
      reducer: (state) => {
        const stepSize = 1;
        state.data.activeStep -= stepSize;
      },
    },
  },
});

export const {
  decreaseModelCreateStep,
  increaseModelCreateStep,
  resetModelCreateStep,
  setConnectorModelStep,
  setDetailsModelStep,
  setNetworkModelStep,
  setImageCreateModelStep,
  setToUpdateModel,
  setIsNewManufacturerModelStep,
} = createModelStepSlice.actions;

export default createModelStepSlice.reducer;
