import { DAYS_FOR_API } from 'utils/datetime';
import { PricingProfileItem, PricingProfileItemApi, PricingProfilePeriod } from '../../types/pricingProfile';

const DEFAULT_PERIOD: PricingProfilePeriod = {
  type: undefined,
  price: undefined,
  startPrice: undefined,
  start: '00:00 am',
};

const PRICING_PROFILE_STATE: PricingProfileItem[] = [0, 1, 2, 3, 4, 5, 6].map((index) => ({
  index,
  periods: [{ ...DEFAULT_PERIOD }],
}));

const mapDayToIndex = (day: string): number => {
  switch (day) {
    case 'mon':
      return 0;
    case 'tue':
      return 1;
    case 'wed':
      return 2;
    case 'thu':
      return 3;
    case 'fri':
      return 4;
    case 'sat':
      return 5;
    case 'sun':
      return 6;
    default:
      return 0;
  }
};

export const getPricingProfileInitialItemsState = () => {
  return PRICING_PROFILE_STATE;
};

export const getDefaultPricingProfilePeriod = (): PricingProfilePeriod => {
  return {
    type: undefined,
    start: '00:30 am',
    price: undefined,
    startPrice: undefined,
  };
};

export const convertPricingProfileToApi = (chargingSchedulePeriod: PricingProfileItem[]): PricingProfileItemApi => {
  const days = {} as PricingProfileItemApi;

  chargingSchedulePeriod.forEach((schedule, index) => {
    days[DAYS_FOR_API[index]] = schedule.periods;
  });

  return days;
};

export const convertPricingProfileFromApi = (
  chargingSchedulePeriodApi: PricingProfileItemApi
): PricingProfileItem[] => {
  const days: PricingProfileItem[] = [...PRICING_PROFILE_STATE].map((item) => {
    return { ...item };
  });

  Object.keys(chargingSchedulePeriodApi).forEach((dayKey) => {
    if (DAYS_FOR_API.includes(dayKey)) {
      const index = mapDayToIndex(dayKey);
      const periods = chargingSchedulePeriodApi[dayKey];
      days[index].periods = [...periods];
    }
  });

  return days;
};

export const isPricingProfilePeriodValid = (period: PricingProfilePeriod): boolean => {
  if (!period) {
    return false;
  }

  return !(
    (period.price === undefined || period.startPrice === undefined)
    // period.price2 === undefined ||
    // period.price3 === undefined ||
    // period.price4 === undefined
  );
};
