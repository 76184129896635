import { Connector } from './connector';
import { ApiGenericCallbackPayload } from './apiGeneric';

export interface Station {
  id: string;
  registered: boolean;
  clientName: string | null;
  clientId: string | null;
  details: StationDetails;
  wiFiSupported: boolean;
  gsmSupported: boolean;
  locationId: null;
  locationName: string;
  connectors: Connector[];
  image: string;
  status: string;
  acceptedCards?: string[];
}

export interface StationDetails {
  name: string;
  model: string;
  manufacturer: string;
  securityCode: string;
  serialNumber: string;
  articleNumber: string;
  maxCurrent: string;
  chargingType: ChargingType;

  autoStart?: boolean | string;
}

export enum ChargingType {
  public = 'public',
  private = 'private',
}

export type ApiUpdateStationSupportedCardsBody = string[] | undefined;

export interface ApiUpdateStationSupportedCardsPayload
  extends ApiGenericCallbackPayload<ApiUpdateStationSupportedCardsBody> {
  stationId: string;
}
