import { Tooltip, IconButton, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

export const DateTimeFiltersPicker = ({ column, type }: any) => {
  const dateFrom = column.getFilterValue()?.from ? new Date(column.getFilterValue()?.from) : null;
  const dateTo = column.getFilterValue()?.to ? new Date(column.getFilterValue()?.to) : null;
  const dateType = column?.getFilterValue()?.[type] ? new Date(column?.getFilterValue()?.[type]) : null;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDateTimePicker
        minDateTime={type === 'to' && dateFrom ? dateFrom : null}
        maxDateTime={type === 'from' && dateTo ? dateTo : null}
        format="dd MMM yyyy, hh:mm a"
        onChange={(newValue) => {
          column?.setFilterValue({
            from: dateFrom,
            to: dateTo,
            [type]: newValue,
          });
        }}
        slotProps={{
          textField: {
            sx: { width: '215px', m: '5px 5px 0 0' },
            placeholder: type === 'from' ? 'Start date' : 'End date',
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Clear filter" placement="right" arrow>
                    <span>
                      <IconButton
                        disabled={!dateType}
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          const columnCurrentFilter = {
                            from: dateFrom,
                            to: dateTo,
                            [type]: null,
                          };
                          if (
                            (columnCurrentFilter?.from === null || columnCurrentFilter?.from === undefined) &&
                            (columnCurrentFilter?.to === null || columnCurrentFilter?.to === undefined)
                          ) {
                            column?.setFilterValue(null);
                          } else {
                            column?.setFilterValue(columnCurrentFilter);
                          }
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </InputAdornment>
              ),
            },
          },
          actionBar: { actions: ['today', 'clear', 'cancel', 'accept'] },
        }}
        value={dateType}
      />
    </LocalizationProvider>
  );
};
