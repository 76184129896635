import { useState } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { convertSecondsInDHHMMSS } from 'utils/datetime';
import { theme } from 'themes/defaultTheme';

interface IProps {
  column: any;
  minValue: number;
  maxValue: number;
  step: number;
  typeTime: boolean;
}

export function CustomRangeSlider({ column, minValue, maxValue, step, typeTime }: IProps) {
  const handleChange = (event: Event, newValue: number | number[]) => {
    column.setFilterValue(newValue as number[]);
  };

  return (
    <Box sx={{ width: '100%', mt: 3.5, px: 2 }}>
      <Slider
        value={[
          column?.getFilterValue()?.[0] !== undefined ? column.getFilterValue()[0] : minValue,
          column?.getFilterValue()?.[1] !== undefined ? column.getFilterValue()[1] : maxValue,
        ]}
        onChange={handleChange}
        valueLabelFormat={typeTime ? convertSecondsInDHHMMSS : undefined}
        valueLabelDisplay="on"
        step={step}
        min={minValue}
        max={maxValue}
        sx={{
          '&.MuiSlider-root span.MuiSlider-thumb': {
            '& .MuiSlider-valueLabel': {
              fontSize: 12,
              fontWeight: 'normal',
              top: 2,
              backgroundColor: 'unset',
              color: theme.palette.text.primary,
              '&:before': {
                display: 'none',
              },
              '& *': {
                background: 'transparent',
                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
              },
            },
          },
          '&.MuiSlider-root span.MuiSlider-thumb:nth-of-type(4)': {
            '& .MuiSlider-valueLabel': {
              top: -10,
            },
          },
        }}
      />
    </Box>
  );
}
