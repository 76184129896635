import { createSlice } from '@reduxjs/toolkit';

interface PageTitleSliceState {
  title: string;
  breadcrumbParams: string[];
}

const initialState: PageTitleSliceState = {
  title: '',
  breadcrumbParams: [],
};

export const pageTitleSlice = createSlice({
  name: 'pageTitle',
  initialState,
  reducers: {
    setPageTitle: (state, action) => {
      state.title = action.payload.title;
      if (action.payload.breadcrumbParams) {
        state.breadcrumbParams = action.payload.breadcrumbParams;
      }
    },
    setPageBreadcrumbParams: (state, action) => {
      state.breadcrumbParams = action.payload.breadcrumbParams;
    },
    resetPageTitle: (state) => {
      state.title = initialState.title;
      state.breadcrumbParams = initialState.breadcrumbParams;
    },
  },
});

export const { resetPageTitle, setPageTitle, setPageBreadcrumbParams } = pageTitleSlice.actions;

export default pageTitleSlice.reducer;
