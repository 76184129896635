import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  ClickAwayListener,
} from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

import Popper from '@mui/material/Popper';

import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { breadcrumbsDictionary, LAYOUT } from 'utils';
import { theme } from 'themes/defaultTheme';
import { logout } from 'redux/slices/user/userSlice';
import { Notifications } from './Notifications';
import { BreadcrumbsComponent } from './BreadcrumbsComponent';

export function Navbar({ openDrawer, isMobile, showDrawer, hideDrawer }) {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorNotificationsEl, setAnchorNotificationsEl] = useState(null);

  const totalUnreadNotifications = useSelector((state) => state.notifications.totalUnread);
  const user = useSelector((state) => state.user.user);
  const pageTitle = useSelector((state) => state.pageTitle);

  const { pathname } = useLocation();
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleNotificationClick({});
  }, [location]);

  const getPageTitle = () => {
    if (pageTitle.title?.length) {
      return pageTitle.title;
    }

    return breadcrumbsDictionary(id)[pathname] ?? '';
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNotificationClick = (event) => {
    setAnchorNotificationsEl((prev) => (prev ? null : event?.currentTarget));
  };

  return (
    <AppBar
      elevation={0}
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        zIndex: 2,
        backgroundColor: 'white',
        width: openDrawer && !isMobile ? `calc(100% - ${LAYOUT.SIDEBAR_WIDTH}px)` : '100%',
      }}
    >
      <Toolbar>
        <Box>
          {openDrawer ? (
            <Tooltip title="Hide sidebar menu">
              <IconButton aria-label="toggle sidebarMenu" color="inherit" onClick={hideDrawer}>
                <MenuOpenIcon sx={{ color: '#000', fontSize: 26 }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Show sidebar menu">
              <IconButton aria-label="toggle sidebarMenu" color="inherit" onClick={showDrawer}>
                <MenuIcon sx={{ color: '#000', fontSize: 26 }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Box sx={{ ml: 2 }}>
          <BreadcrumbsComponent />
          <Typography sx={{ color: '#000000', fontSize: 24 }}>{getPageTitle()}</Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />

        {/* Notifications */}
        <Box>
          <Tooltip title="Open notifications">
            <IconButton
              aria-label="show new notifications"
              color="inherit"
              onClick={(e) => {
                if (!anchorNotificationsEl) {
                  handleNotificationClick(e);
                }
              }}
              aria-describedby="notifications-popper"
            >
              <Badge badgeContent={totalUnreadNotifications} color="error">
                <NotificationsNoneIcon sx={{ color: 'primary.main', fontSize: 26 }} />
              </Badge>
            </IconButton>
          </Tooltip>
          <div>
            <Popper
              id="notifications-popper"
              open={Boolean(anchorNotificationsEl)}
              anchorEl={anchorNotificationsEl}
              style={{
                zIndex: 9998,
                position: 'absolute',
                height: 'calc(100vh - 80px + 12px)',
                width: 650,
                maxWidth: '100vw',
                overflowY: 'hidden',
                paddingTop: '11px',
              }}
            >
              <ClickAwayListener
                onClickAway={(e) => {
                  handleNotificationClick(e);
                }}
              >
                <Box sx={{ height: '100%' }}>
                  <Notifications />
                </Box>
              </ClickAwayListener>
            </Popper>
          </div>
        </Box>

        {/* Settings */}
        <Box sx={{ flexGrow: 0, ml: 2 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                sx={{
                  fontWeight: 600,
                  color: 'primary.main',
                  bgcolor: '#ebebeb',
                  fontSize: 14,
                }}
              >
                {user?.firstName?.substring(0, 1).toUpperCase()}
                {user?.lastName?.substring(0, 1).toUpperCase()}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem
              onClick={() => {
                handleCloseUserMenu();
                navigate('/my-profile');
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PersonOutlineIcon />
                <Typography textAlign="center" sx={{ ml: 1.5 }}>
                  My profile
                </Typography>
              </Box>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleCloseUserMenu();
                dispatch(logout());
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LogoutIcon />
                <Typography textAlign="center" sx={{ ml: 1.5 }}>
                  Logout
                </Typography>
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
