import * as yup from 'yup';
import { escapeRegExp } from './regex';

export const schemaDefault = yup.object().shape({
  key: yup.string().required('This field is required'),
});

export const schemaNumber = yup.object().shape({
  key: yup.number().typeError('The value must be a number').required('This field is required'),
});

export const schemaNumberPositive = yup.object().shape({
  key: yup
    .number()
    .min(0, 'The value must be a positive number')
    .typeError('The value must be a positive number')
    .required('This field is required'),
});

export const schemaNoWhiteSpaces = yup.object().shape({
  key: yup
    .string()
    .test('key', 'This field has spaces', (val) => !/\s/g.test(val ?? ''))
    .required('This field is required'),
});

export const schemaCustomMessage = (message: string) =>
  yup.object().shape({
    key: yup.string().required(message),
  });

export const getSchemaStringMinMaxLength = (value: string, min: number, max: number) =>
  yup.object().shape({
    key: yup
      .string()
      .test(
        'len',
        `${min} to ${max} characters are required`,
        (val) => val?.length !== undefined && val.length >= min && val.length <= max
      )
      .required('This field is required'),
  });

export const jsonSchema = yup.object().shape({
  key: yup
    .string()
    .test('len', `Invalid json object`, (val) => {
      try {
        if (!val) {
          return false;
        }
        JSON.parse(val);
      } catch (e) {
        return false;
      }
      return true;
    })
    .required('This field is required'),
});

export const getSchemaStringExactLength = (value: string, length: number) =>
  yup.object().shape({
    key: yup
      .string()
      .test('len', `${length} characters are required`, (val) => val?.length === length)
      .required('This field is required'),
  });

export const getSchemaStationDelete = (value: string) => {
  const regex = new RegExp(`^${escapeRegExp(value)}$`);

  return yup.object().shape({
    key: yup.string().trim().matches(regex, 'This station ID is incorrect').required('This field is required'),
  });
};

export const getSchemaMinMaxNumber = (min = Number.MIN_VALUE, max = Number.MAX_VALUE) =>
  yup.object().shape({
    key: yup
      .number()
      .integer('This filed is a number')
      .min(min, `Minimum value is ${min}`)
      .max(max, `Maximum value is ${max}`)
      .required('This field is required'),
  });

export const schemaEmail = yup.object().shape({
  key: yup
    .string()
    .test('email', 'Email is not valid', (val) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(val ?? ''))
    .required('This field is required'),
});

export const schemaPhoneNumber = yup.object().shape({
  key: yup
    .string()
    .test('phone', 'Phone number is not valid', (val) => {
      const phoneno = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
      return !!val?.match(phoneno);
    })
    .required('This field is required'),
});

export const schemaUrl = yup.object().shape({
  key: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Invalid URL'
    )
    .required('Invalid URL'),
});

export const schemaPasswordConfirm = (newPassword: string) =>
  yup.object().shape({
    key: yup
      .string()
      .trim()
      .test('confirmNewPassword', 'Password does not match', (val) => val === newPassword)
      .required('This field is required'),
  });

export const schemaPassword = yup.object().shape({
  key: yup
    .string()
    .test('password', 'Password must have at least 6 letters', (val) => val !== undefined && val.length >= 6)
    .required('This field is required'),
});

export const schemaIBAN = yup.object().shape({
  key: yup
    .string()
    .test('iban', 'IBAN is not valid', (val) => {
      const cleared = val?.replace(/\s/g, '');
      return cleared?.length === 24;
    })
    .required('This field is required'),
});

export const validate = (schema: yup.Schema, value?: string | number | object) => {
  try {
    schema.validateSync({ key: value });
    return {
      isValid: schema.isValidSync({ key: value }),
      error: '',
    };
  } catch (e: any) {
    return {
      isValid: schema.isValidSync({ key: value }),
      error: e?.errors ?? '',
    };
  }
};
