import { Box, InputLabel, TextField } from '@mui/material';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from 'themes/defaultTheme';
import { InfoTooltip } from 'components/common/InfoTooltip';
import { InputTextRequiredOrOptional } from 'components/common/input/InputTextRequiredOrOptional';
import { ItemLabelValue } from '../ItemLabelValue';

export function CustomInputText({
  label,
  placeholder = '',
  value,
  setValue,
  validation,
  valueDirty,
  setValueDirty,
  sx = {},
  type = 'text',
  disabled = false,
  showDisabledWithoutOpacity = false,
  inputProps = null,
  extraError = false,
  setExtraError = undefined,
  extraErrorMessage = undefined,
  isSuccess = false,
  containerSx = {},
  infoTooltip = null,
  showCloseButton = false,
  onEnterPress = () => null,
  labelSize = 'lg',
  ...data
}) {
  const input = useRef();

  const extraProps = { ...data };

  if (type === 'numeric') {
    extraProps.inputProps = { inputMode: 'numeric', pattern: '[0-9]|(.)*' };
  }

  // if (type === 'password') {
  //   extraProps.inputProps = { inputMode: 'numeric', pattern: '[0-9]|(.)*' };
  // }

  if (inputProps) {
    extraProps.inputProps = { ...extraProps.inputProps, ...inputProps };
  }

  if (setValueDirty) {
    extraProps.onBlur = () => setValueDirty(true);
  }

  const onInputChange = (e) => {
    setExtraError && setExtraError(false);
    setValue(e);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onEnterPress();
    }
  };

  if (disabled && showDisabledWithoutOpacity) {
    return (
      <Box>
        <ItemLabelValue label={label} value={value} size={labelSize} />
      </Box>
    );
  }

  return (
    <Box sx={[{ width: '100%', position: 'relative' }, containerSx]}>
      {label?.length > 0 && (
        <InputLabel sx={{ display: 'flex', alignItems: 'center' }}>
          {label}
          {!disabled && <InputTextRequiredOrOptional required={validation} />}
          {infoTooltip && <InfoTooltip tooltipComponent={infoTooltip} />}
        </InputLabel>
      )}

      <TextField
        inputRef={input}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        required={!!validation}
        error={validation ? (valueDirty && !validation?.isValid) || extraError : false}
        helperText={(valueDirty && !validation?.isValid && validation?.error) || (extraError && extraErrorMessage)}
        variant="outlined"
        type={type}
        size="small"
        margin="dense"
        sx={{
          width: 'calc(100%)',
          ...sx,
        }}
        InputProps={{ notched: false, sx: { borderRadius: 1 } }}
        FormHelperTextProps={{ style: { marginLeft: 0 } }}
        {...extraProps}
      />

      {value?.length > 0 && showCloseButton && (
        <Box
          onClick={() => onInputChange({ target: { value: '' } })}
          sx={{
            fontSize: theme.components.MuiOutlinedInput.styleOverrides.input.fontSize,
            cursor: 'pointer',
            position: 'absolute',
            right: 8,
            top: 36,
          }}
        >
          <CloseIcon />
        </Box>
      )}

      {isSuccess && (
        <Box sx={{ position: 'absolute', right: -32, top: 34 }}>
          <CheckCircleIcon sx={{ fontSize: 24, color: '#66bb6a' }} />
        </Box>
      )}
    </Box>
  );
}
