// sets null when is set dynamically
export const breadcrumbsDictionary = (id: string | number) => ({
  '/': 'Dashboard',
  '/dashboard': 'Dashboard',

  '/authorizations': 'Authorizations',
  [`/authorizations/${id}`]: null,
  [`/authorizations/${id}/charging-history`]: 'Charging History',
  '/authorizations/activate': 'Activate Cards',
  '/authorizations/activate/single': 'Activate Single Card',
  '/authorizations/activate/multiple': 'Activate Multiple Cards',
  '/authorizations/import': 'Import Cards',
  '/authorizations/import/single': 'Import Single Card',
  '/authorizations/import/multiple': 'Import Multiple Cards',

  '/stations': 'Stations',
  [`/stations/${id}`]: null,
  [`/stations/${id}/details`]: 'Details',
  [`/stations/${id}/pricing`]: 'Pricing',
  [`/stations/${id}/location`]: 'Location',
  [`/stations/${id}/settings`]: 'Settings',
  [`/stations/${id}/charging-history`]: 'Charging History',
  [`/stations/${id}/logs`]: 'Logs',
  [`/stations/${id}/terminal`]: 'Terminal',
  '/stations/activate': 'Activate Stations',
  '/stations/activate/single': 'Activate Single Station',
  '/stations/activate/multiple': 'Activate Multiple Stations',
  '/stations/charging-sessions': 'Charging Sessions',
  '/stations/charging-profiles': 'Charging Profiles',
  '/stations/charging-profiles/create': 'Create Charging Profile',
  [`/stations/charging-profiles/${id}`]: null,

  '/stations/smart-charging': 'Smart Charging',
  '/stations/smart-charging/create': 'Create Smart Charging Group',
  [`/stations/smart-charging/${id}`]: null,
  [`/stations/smart-charging/${id}/details`]: 'Details',

  '/stations/pricing-profiles': 'Pricing Profiles',
  '/stations/pricing-profiles/create': 'Create Pricing Profile',

  '/stations/pricing-profile': 'Stations Pricing Profile',
  '/stations/map': 'Stations Map',
  '/stations/import': 'Import Stations',
  '/stations/import/single': 'Import Single Station',
  '/stations/import/multiple': 'Import Multiple Stations',

  '/stations/models': 'Stations models',
  '/stations/models/create': 'Add station model',
  [`/stations/models/${id}`]: null,

  '/stations/locations': 'Locations',
  '/stations/locations/create': 'Create',

  '/drivers': 'Drivers',
  [`/drivers/${id}`]: null,
  [`/drivers/${id}/billing`]: 'Billing',
  [`/drivers/${id}/charging-history`]: 'Charging History',
  [`/drivers/${id}/authorizations`]: 'Authorizations',
  '/drivers/register': 'Register Drivers',
  '/drivers/register/manual': 'Register Driver',
  '/drivers/register/invite': 'Invite Driver',
  '/drivers/billing': 'Billing',

  '/subsidiaries': 'Subsidiaries',
  '/subsidiaries/create': 'Create Subsidiary',

  '/clients': 'Clients',
  [`/clients/${id}`]: null,
  [`/clients/${id}/stations`]: 'Stations',
  [`/clients/${id}/authorizations`]: 'Authorizations',
  [`/clients/${id}/drivers`]: 'Drivers',
  [`/clients/${id}/users`]: 'Users',
  [`/clients/${id}/billing-plan`]: 'Billing Plan',
  '/clients/register': 'Register Clients',
  '/clients/register/individual': 'Register Individual',
  '/clients/register/invite/individual': 'Invite Individual',
  '/clients/register/company': 'Register Company',
  '/clients/register/invite/company': 'Invite Company',
  '/clients/users': 'Users of Clients',
  [`/clients/users/${id}`]: null,
  '/clients/users/add-client-user': 'Add client user',

  '/charging-apps': 'Charging Applications',

  '/company': 'Company Details',
  '/company/users': 'Users',
  [`/company/users/${id}`]: null,
  '/company/users/add-users': 'Add users',

  '/company/roles': 'Roles',
  '/company/billing': 'Billing',
  '/company/billing/plan': 'Billing plan',
  '/company/customization': 'Customization',

  '/support': 'Support',

  '/upgrade': 'Upgrade',

  '/my-profile': 'My profile',

  '/billing-drivers': 'Drivers invoices',
  '/billing-clients': 'Clients invoices',
});
