const measureTextWidth = (text) => {
  if (text === undefined) return 0;
  const font = 'bold 13px Poppins';
  let canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  const { width } = context.measureText(text);
  canvas.remove();
  canvas = null;
  return Math.ceil(width);
};

export const maxCells = (headerName, cell) => {
  if (cell === undefined) return measureTextWidth(headerName) + 40;
  if (typeof cell === 'number') {
    return Math.max(cell + 40, measureTextWidth(headerName) + 40);
  }
  return Math.max(measureTextWidth(cell) + 40, measureTextWidth(headerName) + 40);
};

export const longestCell = (rows, field, headerName) => {
  const maxHeadCell = measureTextWidth(headerName) + 40;
  return rows.reduce((acc, currentEl) => Math.max(acc, measureTextWidth(currentEl[field]) + 40), maxHeadCell);
};

export const customTableIntervalFilter = (row, _columnIds, filterValue, columnName) => {
  if (filterValue?.from && filterValue?.to)
    return filterValue?.from <= row.getValue(columnName) && row.getValue(columnName) <= filterValue?.to;
  if ((filterValue?.from === null || filterValue?.from === undefined) && filterValue?.to)
    return row.getValue(columnName) <= filterValue?.to;

  if (filterValue?.from && (filterValue?.to === null || filterValue?.to === undefined))
    return filterValue?.from <= row.getValue(columnName);
  return true;
};

export const customRangeSliderFilter = (row, _columnIds, filterValue, columnName) => {
  if (filterValue?.[0] !== null)
    return filterValue[0] <= row.getValue(columnName) && row.getValue(columnName) <= filterValue[1];
  return true;
};

export const customTrueFalse = (row, _columnIds, filterValue, columnName, trueText, falseText) => {
  if (filterValue === trueText) {
    return row.getValue(columnName) === true;
  }
  if (filterValue === falseText) {
    return row.getValue(columnName) === false;
  }
  return 'True False filter error';
};

export const customConnectorStatus = (row, _columnIds, filterValue, columnName) => {
  if (filterValue && filterValue.length === 0) return true;
  if (filterValue && row.getValue(columnName)) {
    let boo = false;
    filterValue.map((el) => {
      if (row.getValue(columnName).includes(el)) boo = true;
      return true;
    });
    return boo;
  }
  return false;
};
