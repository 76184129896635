import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { LAST_AUTH_PATH, loadFromLocalStorage, saveToLocalStorage } from 'utils';
import BackgroundLogin from 'assets/images/login_page.jpg';
import { theme } from 'themes/defaultTheme';
import elvo_logo from 'assets/images/logos/logo_volt_black.png';
import semaparc_logo from 'assets/images/logos/semaparc-logo.svg';

const TITLES = {
  '/auth/login': 'Login',
  '/auth/reset-password': 'Reset password',
  '/auth/forgot-password': 'Forgot password',
};

export default function AuthLayout() {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);

  const isRegisterPage = window.location.pathname.includes('register');

  // useEffect(() => {
  //   let link = document.querySelector("link[rel~='icon']");
  //   if (!link) {
  //     link = document.createElement('link');
  //     link.rel = 'icon';
  //     document.getElementsByTagName('head')[0].appendChild(link);
  //   }
  //   link.href = 'https://stackoverflow.com/favicon.ico';
  //   document.title = 'dfsdfsdfsd';
  //   document.querySelector('meta[name="description"]').setAttribute('content', '22New Description');
  // }, []);

  useEffect(() => {
    if (user?.token) {
      const lastPath = loadFromLocalStorage(LAST_AUTH_PATH);

      if (lastPath) {
        saveToLocalStorage(LAST_AUTH_PATH, null);
        navigate(lastPath);
      } else {
        navigate('/dashboard');
      }
    } else {
      setIsReady(true);
    }
  }, [user?.token]);

  if (!isReady) {
    return null;
  }

  // TEMPORARY
  return (
    <Box>
      {!isRegisterPage ? (
        <Grid container maxWidth="xl" mx="auto" height="100vh" alignItems="center">
          <Grid item sx={{ display: { xs: 'none', md: 'flex' } }} md={6}>
            <img src={BackgroundLogin} alt="login" style={{ width: '100%', borderRadius: theme.shape.borderRadius }} />
          </Grid>
          <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
            <Box>
              <div
                style={{
                  width: '250px',
                  borderBottom: '1px #e0e0e0 solid',
                  textAlign: 'center',
                  paddingBottom: '1rem',
                  display: 'inline-block',
                }}
              >
                <Link to="/auth/login">
                  <img
                    src={window.location.origin.includes('semaparc.elvo.io') ? semaparc_logo : elvo_logo}
                    style={{ width: '200px', cursor: 'pointer' }}
                    alt="elvo logo"
                  />
                </Link>
              </div>

              <Typography variant="h2" component="h1" sx={{ mt: theme.customSpacing.spacingInsideForm }}>
                {TITLES[window.location.pathname] ?? ''}
              </Typography>
            </Box>
            <Outlet />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            backgroundColor: '#ebebeb',
          }}
        >
          <Grid item xs={12} md={12} sx={{ textAlign: 'center', paddingTop: 2, paddingBottom: 2 }}>
            <div
              style={{
                height: '35px',
                display: 'inline-block',
              }}
            >
              <Link to="/auth/login">
                <img
                  src={window.location.origin.includes('semaparc.elvo.io') ? semaparc_logo : elvo_logo}
                  style={{ width: '120px', cursor: 'pointer' }}
                  alt="elvo logo"
                />
              </Link>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              // height - padding - logo - padding logo
              height: 'calc(100vh - 32px - 35px - 16px)',
              mt: 1,
              pl: 4,
              pr: 4,
            }}
          >
            <Box
              sx={{
                overflowY: 'auto',
                pt: 4,
                pb: 2,
                backgroundColor: 'white',
                borderRadius: 1,
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                height: '100%',
              }}
            >
              <Outlet />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
