import { Box, InputLabel, MenuItem, Select } from '@mui/material';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from 'themes/defaultTheme';
import { InputTextRequiredOrOptional } from 'components/common/input/InputTextRequiredOrOptional';
import { ItemLabelValue } from '../ItemLabelValue';

export function CustomSelect({
  showDisabledWithoutOpacity = false,
  placeholder = '',
  value,
  label,
  onChange,
  items,
  disabled = false,
  validation,
  valueDirty,
  setValueDirty,
  showCloseButton = false,
  extraProps = {},
  sx = {},
}) {
  if (setValueDirty) {
    extraProps.onBlur = () => setValueDirty(true);
  }

  const error = validation ? valueDirty && !validation?.isValid : false;
  const helperText = valueDirty && !validation?.isValid && validation?.error;

  if (disabled && showDisabledWithoutOpacity) {
    return <ItemLabelValue label={label} value={value} />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      {label?.length > 0 && (
        <InputLabel sx={{ mb: '8px' }}>
          {label}
          <InputTextRequiredOrOptional required={validation} />
        </InputLabel>
      )}

      <Box sx={{ position: 'relative' }}>
        <Select
          value={value}
          displayEmpty={!!placeholder}
          renderValue={(selected) => {
            if (placeholder?.length && !selected?.length) {
              return <span style={{ opacity: 0.5 }}>{placeholder}</span>;
            }
            let valueToShow = selected;
            items.forEach((item) => {
              if (item.id === selected) {
                valueToShow = item.label;
              }
            });
            return valueToShow;
          }}
          onChange={onChange}
          disabled={disabled}
          error={error}
          size="small"
          sx={[
            {
              width: '100%',
              '& .MuiSelect-select': {
                paddingRight: '58px !important',
                // borderColor: 'rgba(228, 219, 233, 0.25)',
              },
            },
            { ...sx },
          ]}
          {...extraProps}
        >
          {items.map((item) => {
            return (
              <MenuItem
                key={item.id}
                value={item.id}
                sx={{
                  fontSize: theme.components.MuiOutlinedInput.styleOverrides.input.fontSize,
                }}
              >
                {item.label}
              </MenuItem>
            );
          })}
        </Select>

        {value && showCloseButton && (
          <Box
            onClick={() => onChange({ target: { value: '' } })}
            sx={{
              fontSize: theme.components.MuiOutlinedInput.styleOverrides.input.fontSize,
              cursor: 'pointer',
              position: 'absolute',
              right: 28,
              top: 10,
            }}
          >
            <CloseIcon />
          </Box>
        )}
      </Box>
      {error && helperText && (
        <p
          style={{
            color: theme.palette.error.main,
            marginTop: '4px',
            marginBottom: '0px',
            fontSize: '12px',
            fontWeight: '500',
          }}
        >
          {helperText ?? 'This is field is required'}
        </p>
      )}
    </Box>
  );
}
