import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { STATUS_FAILED, STATUS_IDLE, STATUS_LOADING, STATUS_SUCCESS } from 'utils';
import { axiosRequest } from 'api/axiosRequest';

export const fetchManufacturers = createAsyncThunk(
  'manufacturers/fetchManufacturers',
  async ({ text }, { rejectWithValue }) => {
    if (!text?.length) {
      const response = await axiosRequest.get(`/stationSpecs/manufacturers`);
      return response.data.map((item) => {
        return {
          id: `${item}`,
          name: item,
        };
      });
    }
    const response = await axiosRequest.get(`/stationSpecs/manufacturers/${text}`);
    return response.data.map((item, index) => {
      return {
        id: `${item}`,
        name: item,
      };
    });
  }
);

export const manufacturersSlice = createSlice({
  name: 'manufacturers',
  initialState: {
    manufacturers: [],
    manufacturer: {},
    status: STATUS_IDLE,
    error: null,
  },
  reducers: {
    selectManufacturer: {
      reducer: (state, action) => {
        state.manufacturer = {
          id: action.payload.name,
          name: action.payload.name,
        };
      },
    },
    resetManufacturer: {
      reducer: (state, action) => {
        state.manufacturer = {};
      },
    },
  },
  extraReducers: (builder) => {
    builder

      // manufacturers
      .addCase(fetchManufacturers.pending, (state) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchManufacturers.fulfilled, (state, action) => {
        state.status = STATUS_SUCCESS;
        state.manufacturers = action.payload;
      })
      .addCase(fetchManufacturers.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.error.message;
      });
  },
});

export const { resetManufacturer, selectManufacturer } = manufacturersSlice.actions;

export default manufacturersSlice.reducer;
